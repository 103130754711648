import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { translate } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Paginate } from 'src/app/shared/models/paginate.model';
import { User } from 'src/app/shared/models/user/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import {  UntypedFormGroup, Validators, FormGroup, FormBuilder, FormArray, UntypedFormControl } from '@angular/forms';
import { UserServiceIndex } from 'src/app/shared/services/user.service';
import { GroupPaymentType } from 'src/app/shared/models/group-payment-type.model';
import { MessageServiceIndex } from 'src/app/shared/services/message.service';
import { Group } from 'src/app/shared/models/group.model';
import { ActivatedRoute } from '@angular/router';
import { FranchiseService, FranchiseServiceCourseIndex } from '../../services/franchise.service';
import { FranchiseCourseDesign } from '../../models/franchise-course-design.model';
import { FranchiseCourse } from '../../models/franchise-course.model';
import { Franchise } from '../../models/franchise.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UploadComponentQueueType } from 'src/app/groups/module/module.component';
import { UploadModel } from '../../models/upload.model';
import { CourseDesign } from '../../models/course-design.model';
import { ApiValidation } from '../../services/api-validation.service';
import { ModalItemsChooseComponentItem } from '../modal-items-chooser/modal-items-chooser.component';

export interface UploadComponentQueue {
    index: number;
    file: File;
    chunks: number;
    chunk: number;
    error: any;
    response: {data: UploadModel};
};

export enum SectionType {
    Header = 'header',
    Intro = 'intro',
    FAQ = 'faq',
    Program = 'program',
    Form = 'form',
    Advantages = 'advantages',
    Footer = 'footer',
    Hashtags = 'hashtags',
}

interface SectionFields {
    fields?: string[];
    items?: string[];
}

interface DesignCombinations {
    [key: string]: {
        [key: string]: SectionFields;
    };
}

interface PossibleCombinations {
    design: DesignCombinations;
}

@Component({
    selector: 'page-edit-franchise-course',
    templateUrl: './page-edit-franchise-course.component.html',
    styleUrls: ['./page-edit-franchise-course.component.scss']
})


export class PageEditFranchiseCourseComponent implements OnInit, OnDestroy {
    protected readonly PAGINATION_LIMIT: number = environment.pagination.default;
    protected readonly CACHING_MAX_TIME: number = environment.caching.default;

    protected innerLoadingQueue: number = 0;
    protected listSubscription?: Subscription;
    protected subscriptions: Subscription[] = [];
    protected debounceTimeout: number = 750;
    protected searchDebounce: any = null;

    franchiseId = this.route.snapshot.paramMap.get('id') as string;
    courseId = this.route.snapshot.paramMap.get('courseId') as string;
    loading: boolean = false;
    form: UntypedFormGroup|null = null;
    errors: any = {};
    conversationFilters: MessageServiceIndex = {
        limit: this.PAGINATION_LIMIT,
        page: 1,
        q: '',
    };
    usersFilter: UserServiceIndex & {loading: boolean} = {
        limit: this.PAGINATION_LIMIT,
        page: 1,
        q: '',
        loading: false,
        role: 'student',
    };
    selectedImage: any;
    search: string = '';
    filter: string = '';
    ingredient!: string;
    message: string = '';
    paymentTypeList?: Paginate<GroupPaymentType>;
    paymentTypes: Array<{id: number|null, name: string}> = [];
    showPaymentMenu: boolean = false;
    designOptions: FranchiseCourseDesign[] = [];

    user: User = new User;
    franchise: Franchise|null = null;

    student: User| null = null;
    group: Group|null = null;
    course: FranchiseCourse|null = null;
    design: CourseDesign|null = null;
    sectionTypes: string[]|null = null;
    selectedSectionIndex: number | null = null;
    selectedSection: number | null = null;
    selectedItemIndex: number | null = null;
    photo: any = null;
    messageType: ('error'|'success') = 'error';
    uploadIndex: number = 0;
    sectionTypeOptions: any = {};

    firstPage: any = null;
    secondPage: any = null;
    showCertificate: boolean = false;
    showCopySectionsDialog: boolean = false;
    franchiseCourses: Paginate<FranchiseCourse>;
    franchiseCoursesList: ModalItemsChooseComponentItem[] = [];
    franchiseCoursesFilter: FranchiseServiceCourseIndex = {
        page: 1,
        limit: this.PAGINATION_LIMIT,
        q: '',
        include: 'sections.items',
    };

    messageFiles: string = '';
    messageFilesType: ('error'|'success'|'warning') = 'error';
    certificateFontOptions: Array<{value: string|null, label: string}> = [{
        value: 'bahnschrift-semi-condensed',
        label: 'Bahnschrift semi condensed'
    }, {
        value: 'Lumios-Marker',
        label: 'Lumios Marker'
    }];

    certificatePreferenceNameList: any = {
        courseTitle: {type: 'boolean', name: 'certificate.course.title'},
        multiplePage: {type: 'boolean', name: 'certificate.template.multipage'},
//first page
        studentXCoord: {type: 'integer', name: 'certificate.first_page.student.coords.x'},
        studentYCoord:  {type: 'integer', name: 'certificate.first_page.student.coords.y'},
        studentWidth: {type: 'integer', name: 'certificate.first_page.student.width'},
        studentHeight: {type: 'integer', name: 'certificate.first_page.student.height'},
        studentFont: {type: 'string', name: 'certificate.first_page.student.font'},
        studentFontSize: {type: 'integer', name: 'certificate.first_page.student.size'},
        frontDateXCoord: {type: 'integer', name: 'certificate.first_page.date.coords.x'},
        frontDateYCoord: {type: 'integer', name: 'certificate.first_page.date.coords.y'},
        frontDateWidth: {type: 'integer', name: 'certificate.first_page.date.width'},
        frontDateHeight: {type: 'integer', name: 'certificate.first_page.date.height'},
        frontDateFont: {type: 'string', name: 'certificate.first_page.date.font'},
        frontDateFontSize: {type: 'integer', name: 'certificate.first_page.date.size'},
//second page

        secondCourseTitleXCoord: {type: 'integer', name:'certificate.second_page.course.coords.x'},
        secondCourseTitleYCoord: {type: 'integer', name:'certificate.second_page.course.coords.y'},
        secondCourseTitleWidth: {type: 'integer', name: 'certificate.second_page.course.width'},
        secondCourseTitleHeight: {type: 'integer', name: 'certificate.second_page.course.height'},
        secondCourseTitleFont: {type: 'string', name:'certificate.second_page.course.font'},
        secondCourseTitleFontSize: {type: 'integer', name:'certificate.second_page.course.size'},
        certificateNumberXCoord: {type: 'integer', name:'certificate.second_page.cert.coords.x'},
        certificateNumberYCoord: {type: 'integer', name:'certificate.second_page.cert.coords.y'},
        certificateNumberWidth: {type: 'integer', name: 'certificate.second_page.cert.width'},
        certificateNumberHeight: {type: 'integer', name: 'certificate.second_page.cert.height'},
        certificateNumberFont: {type: 'string', name:'certificate.second_page.cert.font'},
        certificateNumberFontSize: {type: 'integer', name:'certificate.second_page.cert.size'},
        heldDateNumberXCoord: {type: 'integer', name:'certificate.second_page.held_date.coords.x'},
        heldDateNumberYCoord: {type: 'integer', name:'certificate.second_page.held_date.coords.y'},
        heldDateNumberWidth: {type: 'integer', name: 'certificate.second_page.held_date.width'},
        heldDateNumberHeight: {type: 'integer', name: 'certificate.second_page.held_date.height'},
        heldDateNumberFont: {type: 'string', name:'certificate.second_page.held_date.font'},
        heldDateNumberFontSize: {type: 'integer', name:'certificate.second_page.held_date.size'},
        secondStudentXCoord: {type: 'integer', name:'certificate.second_page.student.coords.x'},
        secondStudentYCoord: {type: 'integer', name:'certificate.second_page.student.coords.y'},
        secondStudentWidth: {type: 'integer', name: 'certificate.second_page.student.width'},
        secondStudentHeight: {type: 'integer', name: 'certificate.second_page.student.height'},
        secondStudentFont: {type: 'string', name:'certificate.second_page.student.font'},
        secondStudentFontSize: {type: 'integer', name:'certificate.second_page.student.size'},
        secondTopicsXCoord: {type: 'integer', name:'certificate.second_page.topics.coords.x'},
        secondTopicsYCoord: {type: 'integer', name:'certificate.second_page.topics.coords.y'},
        secondTopicsWidth: {type: 'integer', name: 'certificate.second_page.topics.width'},
        secondTopicsHeight: {type: 'integer', name: 'certificate.second_page.topics.height'},
        secondTopicsFont: {type: 'string', name:'certificate.second_page.topics.font'},
        secondTopicsFontSize: {type: 'integer', name:'certificate.second_page.topics.size'},
        secondDateXCoord: {type: 'integer', name:'certificate.second_page.date.coords.x'},
        secondDateYCoord: {type: 'integer', name:'certificate.second_page.date.coords.y'},
        secondDateWidth: {type: 'integer', name: 'certificate.second_page.date.width'},
        secondDateHeight: {type: 'integer', name: 'certificate.second_page.date.height'},
        secondDateFontSize: {type: 'integer', name:'certificate.second_page.date.size'},
        secondDateFont: {type: 'string', name:'certificate.second_page.date.font'},
    }

    currentAllowedFieldsStatus: { [key: string]: boolean } = {};
    currentAllowedItemsStatus: { [key: string]: boolean } = {};
    canAddItems : boolean = false;
    accordionStates: { [key: string]: boolean } = {};
    isItemsAccordionLocked: boolean = false;

    imageShare: {url?: string, path?: string}|null = {
        url: '',
        path: '',
    };

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
            } else {
                this.loading = true;
            }
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    constructor(
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private messageService: MessageService,
        private route: ActivatedRoute,
        private franchiseService: FranchiseService,
        private apiValidation: ApiValidation,
        private locationService: Location,
    ) {
        this.sectionTypes = this.getSectionTypes(SectionType);
    }

    ngOnInit(): void {
        this.user = this.authService?.getUser() ?? new User();

        if (!this.user?.can(['admin', 'marketing'])) {
            this.courseId = this.route.snapshot.paramMap.get('id') as string;
            this.franchiseId = (this.user?.franchise?.id ?? '') as string;
        }

        this.getCourse();
        this.initForm();
        this.getFranchise();
        this.getDesigns();
        this.sectionTypeOptions = this.getSectionTypeOptions();

        this.form?.get('design')?.valueChanges.subscribe(value => {
            this.updateAllowedFieldsAndItems();
        });

        this.sectionControls.valueChanges.subscribe(() => {
            this.updateAllowedFieldsAndItems();
        });

        this.updateAllowedFieldsAndItems();

    }

    ngOnDestroy(): void {
        this.subscriptions?.map(item => item?.unsubscribe());
        this.listSubscription?.unsubscribe();
    }

    protected initForm() {
        this.form = this.formBuilder.group({
            url: new UntypedFormControl('', [
                Validators.maxLength(255),
                Validators.pattern(/^[\p{L}\p{N}]+(?:-[\p{L}\p{N}]+)*$/u)
            ]),
            title: new UntypedFormControl('', [
                Validators.maxLength(255),
            ]),
            description: new UntypedFormControl('', [
                Validators.maxLength(10000),
            ]),
            image_share: new UntypedFormControl('', [
                Validators.maxLength(255),
            ]),
            design: new UntypedFormControl(0, [
                ...(this.user?.can(['admin']) ? [
                    Validators.required
                ] : [])
            ]),
            certificate: new UntypedFormControl(false, []),
            status: new UntypedFormControl(true),
            certificateStatus: new UntypedFormControl(false),
            sections: this.formBuilder.array([]),
            selectedSection: this.formBuilder.group({
                title: [''],
                subtitle: [''],
                description: [''],
                thumbnail: [''],
                image: [''],
                alt: [''],
                type: [''],
                status: [''],
                position: ['']
            }),
            preferences: this.formBuilder.group({
                courseTitle: new UntypedFormControl(false, []),
                multiplePage: new UntypedFormControl(true, []),

                studentXCoord: new UntypedFormControl(0, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                studentYCoord: new UntypedFormControl(0, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                studentWidth: new UntypedFormControl(160, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                studentHeight: new UntypedFormControl(160, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                studentFont: new UntypedFormControl('bahnschrift-semi-condensed', []),
                studentFontSize: new UntypedFormControl(16, [Validators.min(0),Validators.max(200)]),

                frontDateXCoord: new UntypedFormControl(0, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                frontDateYCoord: new UntypedFormControl(0, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                frontDateWidth: new UntypedFormControl(160, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                frontDateHeight: new UntypedFormControl(160, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                frontDateFontSize: new UntypedFormControl(16, [Validators.min(0),Validators.max(200)]),
                frontDateFont: new UntypedFormControl('bahnschrift-semi-condensed', []),

                // second page
                secondCourseTitleXCoord: new UntypedFormControl(0, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                secondCourseTitleYCoord: new UntypedFormControl(0, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                secondCourseTitleWidth: new UntypedFormControl(160, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                secondCourseTitleHeight: new UntypedFormControl(160, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                secondCourseTitleFont: new UntypedFormControl('bahnschrift-semi-condensed', []),
                secondCourseTitleFontSize: new UntypedFormControl(16, [Validators.min(0),Validators.max(200)]),

                certificateNumberXCoord: new UntypedFormControl(0, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                certificateNumberYCoord: new UntypedFormControl(0, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                certificateNumberWidth: new UntypedFormControl(160, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                certificateNumberHeight: new UntypedFormControl(160, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                certificateNumberFont: new UntypedFormControl('bahnschrift-semi-condensed', []),
                certificateNumberFontSize: new UntypedFormControl(16, [Validators.min(0),Validators.max(200)]),

                heldDateNumberXCoord: new UntypedFormControl(0, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                heldDateNumberYCoord: new UntypedFormControl(0, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                heldDateNumberWidth: new UntypedFormControl(160, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                heldDateNumberHeight: new UntypedFormControl(160, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                heldDateNumberFont: new UntypedFormControl('bahnschrift-semi-condensed', []),
                heldDateNumberFontSize: new UntypedFormControl(16, [Validators.min(0),Validators.max(200)]),

                secondStudentXCoord: new UntypedFormControl(0, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                secondStudentYCoord: new UntypedFormControl(0, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                secondStudentWidth: new UntypedFormControl(160, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                secondStudentHeight: new UntypedFormControl(160, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                secondStudentFont: new UntypedFormControl('bahnschrift-semi-condensed', []),
                secondStudentFontSize: new UntypedFormControl(16, [Validators.min(0),Validators.max(200)]),

                secondTopicsXCoord: new UntypedFormControl(0, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                secondTopicsYCoord: new UntypedFormControl(0, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                secondTopicsWidth: new UntypedFormControl(160, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                secondTopicsHeight: new UntypedFormControl(160, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                secondTopicsFont: new UntypedFormControl('bahnschrift-semi-condensed', []),
                secondTopicsFontSize: new UntypedFormControl(16, [Validators.min(0),Validators.max(200)]),

                secondDateXCoord: new UntypedFormControl(0, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                secondDateYCoord: new UntypedFormControl(0, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                secondDateWidth: new UntypedFormControl(160, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                secondDateHeight: new UntypedFormControl(160, [Validators.min(0),Validators.max(10000), Validators.pattern('^[0-9]*$')]),
                secondDateFontSize: new UntypedFormControl(16, [Validators.min(0),Validators.max(200)]),
                secondDateFont: new UntypedFormControl('bahnschrift-semi-condensed', []),
            })
        });
    }

    get sectionControls(): FormArray {
        return this.form?.get('sections') as FormArray;
    }

    createSection(sectionData?: any): FormGroup {
        const sectionGroup = this.formBuilder.group({
            title: [sectionData?.title ?? '', [Validators.min(1), Validators.maxLength(255)]],
            subtitle: [sectionData?.subtitle ?? '', [Validators.min(1), Validators.maxLength(255)]],
            description: [sectionData?.description ?? '', [Validators.min(1), Validators.maxLength(65535)]],
            thumbnail: [sectionData?.thumbnail ?? '', [Validators.min(1), Validators.maxLength(255)]],
            image: [sectionData?.image ?? '', [Validators.min(1), Validators.maxLength(255)]],
            alt: [sectionData?.alt ?? '', [Validators.min(1), Validators.maxLength(255)]],
            type: [sectionData?.type ?? 'header'],
            status: [sectionData?.status ?? 'active'],
            position: [sectionData?.position ?? 1, [Validators.min(1), Validators.maxLength(10)]],
            items: this.formBuilder.array(sectionData?.items?.map((item:any) => this.createItem(item)) || [])
        });

        sectionGroup.get('type')?.valueChanges.subscribe(type => {
            if (type === 'header' || type === 'footer') {
                sectionGroup.get('title')?.setValue(type.charAt(0).toUpperCase() + type.slice(1));
            }
            else {
                sectionGroup.get('title')?.value === 'Header' || sectionGroup.get('title')?.value === 'Footer' ? sectionGroup.get('title')?.setValue('') : sectionGroup.get('title')?.value;
            }

            if (!this.canAddItems) {
                const items = sectionGroup.get('items') as FormArray;
                items.clear();
            }
        });

        if (sectionData?.type === 'header' || sectionData?.type === 'footer') {
            sectionGroup.get('title')?.setValue(sectionData.type.charAt(0).toUpperCase() + sectionData.type.slice(1));
        }

        sectionGroup.get('type')?.updateValueAndValidity();

        return sectionGroup;
    }

    createItem(itemData?: any): FormGroup {
        return this.formBuilder.group({
            title: [itemData?.title ?? '', [Validators.min(1), Validators.maxLength(255)]],
            subtitle: [itemData?.subtitle ?? '', [Validators.min(1), Validators.maxLength(255)]],
            description: [itemData?.description ?? '', [Validators.min(1), Validators.maxLength(65535)]],
            thumbnail: [itemData?.thumbnail ?? '', [Validators.min(1), Validators.maxLength(255)]],
            image: [itemData?.image ?? '',[Validators.min(1), Validators.maxLength(255)]],
            alt: [itemData?.alt ?? '', [Validators.min(1), Validators.maxLength(255)]],
            status: [itemData?.status ?? 'active'],
            position: [itemData?.position ?? 1, [Validators.min(1), Validators.maxLength(10)]]
        });
    }

    addSection(event?: MouseEvent): void {
        event?.preventDefault();
        const sections = this.form?.get('sections') as FormArray;
        sections.push(this.createSection());
    }

    onCopySectionsFromCourse(event?: MouseEvent): void {
        event?.preventDefault();

        this.franchiseCoursesFilter = {
            q: '',
            page: 1,
            limit: this.PAGINATION_LIMIT,
            include: 'sections.items',
        };
        this.getFranchiseCourses();
        this.showCopySectionsDialog = true;
    }

    onFranchiseCourseListMore(additional: {q: string, reset: boolean}): void {
        if (additional.reset) {
            this.franchiseCourses = null;
        }

        this.franchiseCoursesFilter.q = additional.q;
        this.getFranchiseCourses(!additional.reset);
    }

    onSelectSectionsToCopy(selected?: number[]): void {
        this.franchiseCourses?.data
            ?.filter(course => selected?.includes(course.id))
            ?.map(course => course.sections)
            ?.flat()
            ?.forEach(section => {
                const sectionGroup = this.createSection({
                    ...section,
                    status: section.status === 'active'
                });
                this.sectionControls.push(sectionGroup);

                const itemsArray = sectionGroup.get('items') as FormArray;
                itemsArray.clear();

                section.items?.forEach(item => {
                    const itemGroup = this.createItem({
                        ...item,
                        status: item.status === 'active'
                    });
                    itemsArray.push(itemGroup);
                });
            });
    }

    protected getFranchiseCourses(appendData?: boolean): void {
        if (this.loading) {
            return;
        }

        if (appendData && this.franchiseCourses?.data?.length) {
            if (this.franchiseCourses?.reachEnd()) {
                return;
            }
            this.franchiseCoursesFilter.page = this.franchiseCourses?.nextPage();
        } else if (!this.franchiseCourses?.data?.length) {
            this.franchiseCoursesFilter.page = 1;
        }

        this.loadingQueue++;
        const subscription = this.franchiseService.getCoursesList(this.franchise?.id, this.franchiseCoursesFilter).subscribe({
            next: response => {
                this.loadingQueue--;

                if (appendData && this.franchiseCourses) {
                    response.data = [
                        ...this.franchiseCourses?.data ?? [],
                        ...response?.data ?? [],
                    ];
                }

                this.franchiseCourses = response;
                this.franchiseCoursesList = this.franchiseCourses?.data?.map(item => {
                    return {
                        value: item.id,
                        label: item.title ?? item?.course?.title ?? '',
                        description: item.id === this.course?.id
                            ? translate('Текущ курс')
                            : translate('{{n}} налични секции', {n: item.sections?.length}),
                        disabled: item.id === this.course?.id || !item?.sections?.length,
                    } as ModalItemsChooseComponentItem;
                }).slice();
            },
            error: error => {
                this.loadingQueue--;

                this.franchiseCoursesList = [];
            }
        });
        this.subscriptions.push(subscription);

    }

    addElement(): void {
        if (this.selectedSectionIndex === null) {
            return;
        }

        const section = (this.form?.get('sections') as FormArray).at(this.selectedSectionIndex) as FormGroup;
        const items = section.get('items') as FormArray;
        items.push(this.createItem());
    }

    selectItem(index: number): void {
        this.selectedItemIndex = index;
    }

    get selectedSectionItems(): FormArray {
        if (this.selectedSectionIndex !== null) {
            const section = this.sectionControls.at(this.selectedSectionIndex) as FormGroup;
            return section.get('items') as FormArray;
        }
        return this.formBuilder.array([]);
    }

    onSubmit(event?: Event): void {
        if (this.loading) {
            return;
        }

        if (this.form?.invalid) {
            this.errors = this.form?.controls;

            this.messageService.add({
                severity: 'error',
                summary: translate('Грешка!'),
                detail: translate('Моля, проверете полетата отново!'),
            });
            this.form.markAllAsTouched();

            return;
        }

        this.form?.get('image_share')?.setValue((this.imageShare?.path || '').trim());

        const formData = this.form?.getRawValue();
        formData.image_share = this.form.get('image_share')?.value || null;
        formData.certificateStatus = formData.certificateStatus ? 'active' : 'inactive';
        formData.status = formData.status ? 'active' : 'inactive';
        formData.sections.forEach((section: any, sectionIndex: number) => {
            section.position = sectionIndex + 1;
            section.status = section.status ? 'active' : 'inactive';

            section.items.forEach((item: any, itemIndex: number) => {
                item.position = itemIndex + 1;
                item.status = item.status ? 'active' : 'inactive';

                item.thumbnail = item.thumbnail || '';
                item.image = item.image || '';
            });
        });

        let preferenceList: any = [];

        Object.keys(formData.preferences).forEach((item: any) => {
            preferenceList.push({
                type: this.certificatePreferenceNameList[item].type ?? 'boolean',
                visibility: 'public',
                name: this.certificatePreferenceNameList[item].name ?? '',
                value: formData.preferences[item].toString()
            })
        });


        formData.preferences = preferenceList;

        if (this.firstPage) {
            formData.preferences.push({
                type: 'string',
                visibility: 'public',
                name: 'certificate.first_page.template',
                value: this.firstPage.path
            });
        }

        if (this.secondPage) {
            formData.preferences.push({
                type: 'string',
                visibility: 'public',
                name: 'certificate.second_page.template',
                value: this.secondPage.path
            });
        }

        if (!this.showCertificate) {
            if (this.course?.preferences) {
                formData.preferences = formData.preferences?.filter((preference: any) => !preference.name?.startsWith('certificate.'));
            } else {
                delete formData.preferences;
            }
        }

        if (formData['selectedSection']) {
            delete formData['selectedSection'];
        }


        this.loadingQueue++;
        const subscription = this.franchiseService.updateCourse(+this.franchiseId ?? 0, +this.courseId ?? 0, formData).subscribe({
            next: response => {
                this.loadingQueue--;

                this.messageService.add({
                    severity: 'success',
                    summary: translate('Успешно!'),
                    detail: translate('Успешно редактиран курс!'),
                });

                this.getCourse();
            },
            error: (error: any) => {
                this.loadingQueue--;
                this.apiValidation.checkBadRequest(error, this.form).then((data: any) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: translate('Грешка!'),
                        detail: data.message
                    });
                    this.errors = data.errors;
                });
            }
        });
        this.subscriptions.push(subscription);
    }

    protected getCourse(): void {
        const subscription = this.franchiseService.getCourse(+this.franchiseId ?? 0, +this.courseId ?? 0).subscribe(response => {

            this.course = response.data;
            if (this.user?.role?.name === 'partner') {
                this.design = response.data?.design ?? null;
            }

            this.form?.patchValue({
                url: this.course.url,
                description: this.course.description,
                image_share: this.course.image_share,
                title: this.course.title,
                design: this?.user?.role?.name === 'admin' ? this.course?.design?.id : null,
                status: this.course.status === 'active',
                certificate: this.course?.certificate ?? false,
            });

            this.imageShare = {
                url: (this.course.image_share || ''),
                path: (this.course.image_share || ''),
            };

            const preferencesFormGroup = this.form?.get('preferences') as FormGroup;

            this.course.preferences?.forEach(coursePreference => {
                const matchingKey = Object.keys(this.certificatePreferenceNameList).find(key => this.certificatePreferenceNameList[key].name == coursePreference.name)

                if (matchingKey) {
                    preferencesFormGroup.get(matchingKey)?.setValue(coursePreference.value)
                }

                this.firstPage = coursePreference?.name == 'certificate.first_page.template'
                    ? {
                        url: coursePreference.value,
                        path: coursePreference.value?.toString().substring(coursePreference.value?.toString().indexOf('/uploads')),
                        title: coursePreference.value?.toString().split('/').pop()
                    }
                    : this.firstPage;
                this.secondPage = coursePreference?.name == 'certificate.second_page.template'
                    ? {
                        url: coursePreference.value,
                        path: coursePreference.value?.toString().substring(coursePreference.value?.toString().indexOf('/uploads')),
                        title: coursePreference.value?.toString().split('/').pop()
                    }
                    : this.secondPage;
            });

            this.showCertificate = this.course?.preferences?.length ? true : this.showCertificate;

            while (this.sectionControls.length !== 0) {
                this.sectionControls.removeAt(0);
            }

            this.course?.sections?.forEach(section => {
                const sectionGroup = this.createSection({
                    ...section,
                    status: section.status === 'active'
                });
                this.sectionControls.push(sectionGroup);

                const itemsArray = sectionGroup.get('items') as FormArray;
                itemsArray.clear();

                section.items?.forEach(item => {
                    const itemGroup = this.createItem({
                        ...item,
                        status: item.status === 'active'
                    });
                    itemsArray.push(itemGroup);
                });
            });
        });
        this.subscriptions.push(subscription);
    }


    protected getFranchise() {
        const subscription = this.franchiseService.getFranchise(+this.franchiseId ?? 0).subscribe({
            next: data => {
                this.franchise = data?.data;
            },
            error: error => {
                this.messageService.add({
                    severity: 'error',
                    detail: translate('Партньорът не съществува!'),
                });
            }
        });
        this.subscriptions.push(subscription);
    }

    onTemplateFinished({files}: {files: UploadComponentQueue[]}, pageNumber: number): void {
        if (!files || !files.length) {
            this.messageFiles = 'Invalid format, upload issue or file is too big';
            this.messageFilesType = 'error';
        } else {
            files.map(item => {
                if (item.error) {
                    this.messageType = 'error';
                    this.message = item.file.name + ": " + item.error;

                    this.messageFiles = translate("Невалиден формат, проблем с качването или файлът е прекалено голям: ") + item.file.name;

                    this.messageFilesType = 'error';
                    return;
                }

                this.message = '';
                this.messageFiles = '';

                if (pageNumber === 1) {
                    this.firstPage = item.response.data;
                    this.firstPage.title = item.file.name;
                    this.firstPage.size_kb = item.file.name;
                } else {
                    this.secondPage = item.response.data;
                    this.secondPage.title = item.file.name;
                    this.secondPage.size_kb = item.file.name;
                }
            });
        }
    }

    onTemplateRemove(file: any, pageNumber: number) {
        if (pageNumber === 1) {
            this.firstPage = null;
        } else {
            this.secondPage = null;
        }
    }

    protected getDesigns() {
        this.loadingQueue++;

        const subscription = this.franchiseService.getCourseDesigns().subscribe({
            next: data => {
                this.loadingQueue--;
                this.designOptions = data?.data;
            },
            error: error => {
                this.loadingQueue--;

                this.messageService.add({
                    severity: 'error',
                    detail: translate('Грешка при извличането на курсове!'),
                });
            }
        });
        this.subscriptions.push(subscription);
    }


    private getSectionTypes(enumObj: any): any[] {
        return Object.keys(enumObj).map(key => {
            return {
                id: enumObj[key],
                title: key
            };
        });
    }

    drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.sectionControls.controls, event.previousIndex, event.currentIndex);
        const sections = this.form?.get('sections')
        if(sections) {
            sections.setValue(this.sectionControls.controls.map(control => control.value));
        }

        this.selectSection(event.currentIndex);
    }

    selectSection(index: number): void {
        this.selectedSectionIndex = index;
        this.updateAllowedFieldsAndItems();
        this.accordionStates = {};
        this.isItemsAccordionLocked = false;
    }

    get selectedSectionFormGroup(): FormGroup {
        return this.sectionControls.at(this.selectedSectionIndex ?? 0) as FormGroup;
    }


    addItem(): void {
        if (this.selectedSectionIndex === null || !this.canAddItems) {
            return;
        }

        const items = this.selectedSectionItems;
        items.push(this.createItem());
    }

    dropItem(event: CdkDragDrop<any[]>): void {
        if (this.selectedSectionIndex !== null) {
            const items = this.selectedSectionItems;
            moveItemInArray(items.controls, event.previousIndex, event.currentIndex);
            items.setValue(items.controls.map(control => control.value));
        }
    }

    getFormGroupAtIndex(index: number): FormGroup {
        return this.selectedSectionItems.at(index) as FormGroup;
    }

    removeSection(index: number): void {
        this.sectionControls.removeAt(index);
    }

    removeItem(index: number): void {
        this.selectedSectionItems.removeAt(index);
    }

    onFileFinished(event: { files: UploadComponentQueueType<UploadModel>[] }, isThumbnail: boolean, sectionIndex: number, itemIndex?: number): void {
        if (!event.files || !event.files.length || event.files[0].error) {
            this.message = translate('Невалидна снимка, или файлът е твърде голям.');
            this.messageType = 'error';
            return;
        }

        const fileData = {
            path: event.files[0].response.data.path,
            url: event.files[0].response.data.url,
        };

        if (typeof itemIndex === 'number') {
            // It's an item upload
            const itemFormGroup = (this.sectionControls.at(sectionIndex) as FormGroup).get('items') as FormArray;
            const itemControl = itemFormGroup.at(itemIndex) as FormGroup;
            itemControl.get(isThumbnail ? 'thumbnail' : 'image')?.setValue(fileData.url);
        } else {
            // It's a section upload
            const sectionFormGroup = this.sectionControls.at(sectionIndex) as FormGroup;
            sectionFormGroup.get(isThumbnail ? 'thumbnail' : 'image')?.setValue(fileData.url);
        }
    }

    onImageShareFinished({files}: {files: UploadComponentQueueType<UploadModel>[]}): void {
        if (!files || !files.length || files[0].error) {
            this.message = translate('Невалидна снимка, или файлът е твърде голям.');
            this.messageType = 'error';
        } else {
            this.imageShare = {
                path: files[0].response.data.path,
                url: files[0].response.data.url,
            };
        }
    }

    getPhotoUrl(isThumbnail: boolean, sectionIndex: number, itemIndex?: number): string {
        const section = this.sectionControls.at(sectionIndex) as FormGroup;
        if (typeof itemIndex === 'number') {
            const item = (section.get('items') as FormArray).at(itemIndex) as FormGroup;
            return item.get(isThumbnail ? 'thumbnail' : 'image')?.value;
        } else {
            return section.get(isThumbnail ? 'thumbnail' : 'image')?.value;
        }
    }

    removeFile(isThumbnail: boolean, sectionIndex: number, itemIndex?: number): void {
        const section = this.sectionControls.at(sectionIndex) as FormGroup;
        if (typeof itemIndex === 'number') {
            const item = (section.get('items') as FormArray).at(itemIndex) as FormGroup;
            item.get(isThumbnail ? 'thumbnail' : 'image')?.setValue('');
        } else {
            section.get(isThumbnail ? 'thumbnail' : 'image')?.setValue('');
        }
    }

    getSectionTypeOptions(): {label: string, value: string}[] {
        return Object.keys(SectionType).map(key => {
          return {
            label: key.charAt(0).toUpperCase() + key.slice(1),
            value: SectionType[key as keyof typeof SectionType]
          };
        });
    }

    private updateAllowedFieldsAndItems(): void {
        const designId = this.design?.name ?? this.form?.get('design')?.value;

        const designKey = designId;

        const sectionType = this.selectedSectionFormGroup?.get('type')?.value;

        this.currentAllowedFieldsStatus = {};
        this.currentAllowedItemsStatus = {};
        this.canAddItems = false;

        const allPossibleFields = ['title', 'subtitle', 'description', 'thumbnail', 'image', 'alt'];
        const allPossibleItems = ['title', 'subtitle', 'description', 'image', 'alt', 'subscription'];

        allPossibleFields.forEach(field => {
            this.currentAllowedFieldsStatus[field] = true;
        });

        allPossibleItems.forEach(item => {
            this.currentAllowedItemsStatus[item] = true;
        });

        this.canAddItems = allPossibleItems.length > 0;
    }

    getSectionTitleDisplay(sectionType: string, index: number): string {
        const capitalizedType = sectionType.charAt(0).toUpperCase() + sectionType.slice(1);
        const sectionsOfType = this.sectionControls.controls.map(ctrl => ctrl.get('type')?.value);
        const typeCount = sectionsOfType.slice(0, index).filter(type => type === sectionType).length;

        return typeCount > 0 ? `${capitalizedType} ${typeCount + 1}` : capitalizedType;
    }

    getItemTitleDisplay(index: number): string {
        return `Item ${index + 1}`;
    }

    toggleAccordionState(position: number): void {
        this.accordionStates[position] = !this.accordionStates[position];

        this.isItemsAccordionLocked = Object.values(this.accordionStates).some(state => state);
    }

    addCertificateStyle(): void {
        this.showCertificate = !this.showCertificate;
    }

}
