import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { translate } from '@ngneat/transloco';

export interface ApiValidationBadResponse {
    errors: any;
    message: string|null;
    first?: string|null;
};

interface FieldError {
    code: number;
    rule: string;
    message: string;
};

@Injectable({
    providedIn: 'root'
})
export class ApiValidation {
    public readonly ERROR_ACCEPTED                          : number = 101;
    public readonly ERROR_ACTIVE_URL                        : number = 102;
    public readonly ERROR_AFTER                             : number = 103;
    public readonly ERROR_AFTER_OR_EQUAL                    : number = 104;
    public readonly ERROR_ALPHA                             : number = 105;
    public readonly ERROR_ALPHA_DASH                        : number = 106;
    public readonly ERROR_ALPHA_NUM                         : number = 107;
    public readonly ERROR_ARRAY                             : number = 108;
    public readonly ERROR_BAIL                              : number = 109;
    public readonly ERROR_BEFORE                            : number = 110;
    public readonly ERROR_BEFORE_OR_EQUAL                   : number = 111;
    public readonly ERROR_BETWEEN                           : number = 112;
    public readonly ERROR_BOOLEAN                           : number = 113;
    public readonly ERROR_CONFIRMED                         : number = 114;
    public readonly ERROR_DATE                              : number = 115;
    public readonly ERROR_DATE_EQUALS                       : number = 116;
    public readonly ERROR_DATE_FORMAT                       : number = 117;
    public readonly ERROR_DIFFERENT                         : number = 118;
    public readonly ERROR_DIGITS                            : number = 119;
    public readonly ERROR_DIGITS_BETWEEN                    : number = 120;
    public readonly ERROR_DIMENSIONS                        : number = 121;
    public readonly ERROR_DISTINCT                          : number = 122;
    public readonly ERROR_EMAIL                             : number = 123;
    public readonly ERROR_ENDS_WITH                         : number = 124;
    public readonly ERROR_EXCLUDE_IF                        : number = 125;
    public readonly ERROR_EXCLUDE_UNLESS                    : number = 126;
    public readonly ERROR_EXISTS                            : number = 127;
    public readonly ERROR_FILE                              : number = 128;
    public readonly ERROR_FILLED                            : number = 129;
    public readonly ERROR_GT                                : number = 130;
    public readonly ERROR_GTE                               : number = 131;
    public readonly ERROR_IMAGE                             : number = 132;
    public readonly ERROR_IN                                : number = 133;
    public readonly ERROR_IN_ARRAY                          : number = 134;
    public readonly ERROR_INTEGER                           : number = 135;
    public readonly ERROR_IP                                : number = 136;
    public readonly ERROR_IPV4                              : number = 137;
    public readonly ERROR_IPV6                              : number = 138;
    public readonly ERROR_JSON                              : number = 139;
    public readonly ERROR_LT                                : number = 140;
    public readonly ERROR_LTE                               : number = 141;
    public readonly ERROR_MAX                               : number = 142;
    public readonly ERROR_MIMETYPES                         : number = 143;
    public readonly ERROR_MIMES                             : number = 144;
    public readonly ERROR_MIN                               : number = 145;
    public readonly ERROR_NOT_IN                            : number = 146;
    public readonly ERROR_NOT_REGEX                         : number = 147;
    public readonly ERROR_NULLABLE                          : number = 148;
    public readonly ERROR_NUMERIC                           : number = 149;
    public readonly ERROR_PASSWORD                          : number = 150;
    public readonly ERROR_PRESENT                           : number = 151;
    public readonly ERROR_REGEX                             : number = 152;
    public readonly ERROR_REQUIRED                          : number = 153;
    public readonly ERROR_REQUIRED_IF                       : number = 154;
    public readonly ERROR_REQUIRED_UNLESS                   : number = 155;
    public readonly ERROR_REQUIRED_WITH                     : number = 156;
    public readonly ERROR_REQUIRED_WITH_ALL                 : number = 157;
    public readonly ERROR_REQUIRED_WITHOUT                  : number = 158;
    public readonly ERROR_REQUIRED_WITHOUT_ALL              : number = 159;
    public readonly ERROR_SAME                              : number = 160;
    public readonly ERROR_SIZE                              : number = 161;
    public readonly ERROR_STARTS_WITH                       : number = 162;
    public readonly ERROR_STRING                            : number = 163;
    public readonly ERROR_TIMEZONE                          : number = 164;
    public readonly ERROR_UNIQUE                            : number = 165;
    public readonly ERROR_URL                               : number = 166;
    public readonly ERROR_UUID                              : number = 167;

    // default and custom rules
    public readonly ERROR_UNKNOWN                           : number =  -1;
    public readonly ERROR_RECAPTCHA                         : number = 301;
    public readonly ERROR_PASSWORD_VALID                    : number = 302;

    public readonly keywords = {
        [this.ERROR_SIZE]:                                  'maxlength',
        [this.ERROR_MAX]:                                   'maxlength',
        [this.ERROR_MIN]:                                   'minlength',
        [this.ERROR_REQUIRED]:                              'required',
        [this.ERROR_REQUIRED_IF]:                           'required',
        [this.ERROR_REQUIRED_UNLESS]:                       'required',
        [this.ERROR_REQUIRED_WITH]:                         'required',
        [this.ERROR_REQUIRED_WITH_ALL]:                     'required',
        [this.ERROR_REQUIRED_WITHOUT]:                      'required',
        [this.ERROR_REQUIRED_WITHOUT_ALL]:                  'required',
        [this.ERROR_UNIQUE]:                                'notunique',
        [this.ERROR_PASSWORD_VALID]:                        'incorrect',
        [this.ERROR_EMAIL]:                                 'email',
        [this.ERROR_REGEX]:                                 'pattern',
    };


    public checkBadRequest(status: any, form?: UntypedFormGroup|null, anyErrorOnly: boolean = false): Promise<ApiValidationBadResponse> {
        return new Promise((resolve, reject) => {
            let first: any = null;

            if (status && "status" in status && status.status === 400 && "error" in status && "errors" in status.error) {
                for (let field in status.error.errors) {
                    let errorsList: {[key: string]: any} = {};
                    let control = form && form.get(field);

                    if (!control) {
                        !first && (first = status.error.errors[field][0].message || status.error.errors[field][0]);
                        continue;
                    }

                    let error: FieldError|null = null;
                    for (error of status.error.errors[field]) {
                        if (!anyErrorOnly && error && error.code in this.keywords && this.keywords[error.code]) {
                            errorsList[this.keywords[error.code]] = true;

                        } else if (!anyErrorOnly && error?.rule) {
                            errorsList[error?.rule ?? 'error'] = true;
                        } else {
                            errorsList['any'] = error?.message || error;
                        }

                        !first && (first = error?.message || error);
                    }

                    control.markAsDirty();
                    control.markAsTouched();
                    control.setErrors(errorsList);
                }

                setTimeout(() => {
                    resolve({
                        errors: form && form.controls,
                        message: translate('Моля, проверете полетата отново!'),
                        first,
                    });
                }, 10);
            } else {
                let error = status?.error?.error || status?.error?.message || null;
                let message = status.status === 400 ? translate('Моля, проверете полетата отново!')
                    : status.status === 401 ? translate('Впишете се отново.')
                    : status.status === 403 ? translate('Няма достъп до този ресурс или информация.')
                    : status.status === 404 ? translate('Този запис е вече изтрит или несъществува.')
                    : status.status === 500 ? translate('Вътрешна сървърна грешка.')
                    : null;

                resolve({
                    errors: {},
                    message: error || message || 'Неизвестна грешка.',
                    first: null,
                });
            }
        });
    }

    public handleServerError(status: any, defaultMessage: string = 'Неизвестна грешка.'): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!status
                || !("status" in status)
                || status.status !== 400
                || !("error" in status)
                || !("errors" in status.error)
            ) {
                reject();
                return;
            }

            let errors: {[name: string]: string} = {};

            for (let field in status.error.errors) {
                if (!status.error.errors[field].length) {
                    continue;
                }

                let error: FieldError = status.error.errors[field][0];
                errors[field] = error.message || defaultMessage;
            }

            resolve(errors);
        });
    }
};
