export class ProjectMessageUser {
    name?: string;
    lastname?: string;
    email?: string;
    image?: string;

    static fromJson(fields: any): ProjectMessageUser {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
