import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
    name: 'price'
})
export class PricePipe implements PipeTransform {

    constructor(
        private decimalPipe: DecimalPipe
    ) { }

    transform(value?: number|string, currency: string|null = 'лв.', prefixed: boolean = false, spaced: boolean = true): string {
        return [
            prefixed ? currency : null,
            this.decimalPipe.transform(value || 0, '1.2-2'),
            !prefixed ? currency : null,
        ].filter(item => item && item?.length > 0)
        .join(spaced ? "\u00A0" : '');
    }
}

