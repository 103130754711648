import { TestResultAnswer } from "./module-test-result-answer.model";
import { TestPracticeResult } from "./test-practice-result.model";

export class ModuleTestResult {
    id?: number;
    exam?: number;
    timeout?: number;
    correct?: number;
    result?: number;
    created_at?: string;
    expired_at?: string;
    finished_at?: string;
    current?: string;
    is_expired?: string;
    remain_second?: number;
    parent_id?: number;
    type?: number;
    elapsed?: number;
    answers?: TestResultAnswer[];
    practice?: TestPracticeResult;
    summary?: string;

    static fromJson(fields: any): ModuleTestResult {
        let item = Object.assign(new this(), fields);

        item.answers = fields?.answers?.map((item: any) => TestResultAnswer.fromJson(item));
        item.practice = TestPracticeResult.fromJson(fields?.practice);

        return item;
    }
}
