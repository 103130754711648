<upload type="presentation"
        [class.text-nowrap]="variant === 'box'"
        [disabled]="loading"
        [maxSize]="maxSize * 1024"
        [files]="files"
        [allowMimes]="allowMimes"
        [disabled]="disabled"
        [type]="type"
        (onFileUploaded)="onFileUploaded($event)"
        (onFileError)="onFileError($event)"
        (onFilesFinished)="onFilesFinished($event)"
        (onFileProgress)="onFileProgress($event)"
>
    <ng-template itemTemplate="default" let-isButtonHover>
        <div class="upload" [class.hover]="isButtonHover" *ngIf="variant === 'box'">
            <div class="icon mb-3"><i class="far fa-file-alt fa-fw text-primary fa-2x"></i></div>

            <div class="info">
                {{ 'Добави файл' | transloco }}<br />
                {{ '\{{ maxSize }\} MB макс.' | transloco:{maxSize: maxSize} }}
            </div>
        </div>
        <div class="upload-inline btn btn-primary" *ngIf="variant === 'inline'">
            <div class="d-flex m-auto align-items-center">
                <i class="far fa-file-alt fa-fw fa-2x me-2"></i>
                <span class="align-self-center">
                    {{ 'Добави файл \{{ maxSize }\} MB макс.' | transloco:{maxSize: maxSize} }}
                </span>
            </div>
        </div>
    </ng-template>
    <ng-template itemTemplate="loading">
        <div class="text-center mx-4 my-2">
            <div class="d-flex align-items-center justify-content-center">
                <small class="position-absolute"> {{ (progress ?? 0) > 0 ? progress : 0 }}%</small>
                <i class="d-inline-block fas fa-spinner fa-4x fa-pulse mx-auto text-secondary mx-auto"></i>
            </div>
        </div>
    </ng-template>
</upload>
