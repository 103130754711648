import { User } from "./user/user.model";

export class Conversation {
    id?: number;
    title?: string;
    message?: string;
    date?: Date;
    user: User = new User;
    online?: boolean;
    counters?: {
        total?: number;
        seen?: number;
        new?: number;
    };

    static fromJson(fields: any): Conversation {
        let item = Object.assign(new this(), fields);

        item.date = fields.date instanceof Date ? fields.date : new Date(fields.date);
        item.user = User.fromJson(fields?.user);
        item.counters = Object.assign({
            total: 0,
            seen: 0,
            new: 0
        }, fields?.counters);

        return item;
    }
}
