import { User } from "./user/user.model";

export class HomeworkMessage {
    id?: number;
    file?: string;
    filename?: string;
    content?: string;
    created?: Date;
    sender?: User;
    recipient?: User;

    static fromJson(fields: any): HomeworkMessage {
        let item = Object.assign(new this(), fields);
        item.sender = User.fromJson(fields?.sender);
        item.recipient = User.fromJson(fields?.recipient);
        item.created = new Date(fields.created);
        return item;
    }
}
