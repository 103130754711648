export class CareerApplication {
    id?: number;
    career?: number;
    user?: number;
    created?: string;

    static fromJson(fields: any): CareerApplication {
        let item = Object.assign(new this(), fields);
        return item;
    }
}

