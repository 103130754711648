import { FranchisePlanFeatures } from "./franchise-plan-features.mode";

export type FranchisePlanStatus = ('active'|'inactive');

export class FranchisePlan {
    id?: number;
    title?: string;
    description?: string;
    price?: number;
    position?: number;
    status?: FranchisePlanStatus;
    features?: FranchisePlanFeatures;

    static fromJson(fields: any): FranchisePlan {
        let item = Object.assign(new this(), fields);
        item.features = FranchisePlanFeatures.fromJson(fields?.course);

        return item;
    }
}
