
<div class="module-card d-flex">
    <h3 class="mb-0">
        {{ 'Модул \{{moduleNumber}\}' | transloco:{moduleNumber: module?.number} }}
    </h3>

    <p class="mb-0 align-self-center">
        {{ module?.title }}
    </p>
</div>
