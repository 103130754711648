import { CourseShort } from "./course-short.model";
import { Course } from "./course.model";
import { FranchiseCourseDesign } from "./franchise-course-design.model";
import { FranchiseCourseSection } from "./franchise-course-section.model";
import { FranchisePreference } from "./franchise-preference.model";
import { Franchise } from "./franchise.model";
import { GroupLanding } from "./group-landing.model";

export type FranchiseStatus = ('active'|'inactive');

export class FranchiseCourse {
    id?: number;
    status?: FranchiseStatus;
    url?: string;
    title?: string;
    description?: string;
    image_share?: string;
    certificate?: number;
    groups_count?: number;
    course?: Course;
    sections?: FranchiseCourseSection[];
    design?: FranchiseCourseDesign;
    preferences?: FranchisePreference[];
    groups?: GroupLanding[];
    franchise?: Franchise;

    static fromJson(fields: any): FranchiseCourse {
        let item = Object.assign(new this(), fields);

        item.course = Course.fromJson(fields?.course);
        item.sections = fields?.sections?.map((item: any) => FranchiseCourseSection.fromJson(item));
        item.design = FranchiseCourseDesign.fromJson(fields?.design);
        item.preferences = fields?.preferences?.map((item: any) => FranchisePreference.fromJson(item));
        item.groups = fields?.groups?.map((item: any) => GroupLanding.fromJson(item));
        item.franchise = Franchise.fromJson(fields?.franchise);

        return item;
    }
}
