import { translate } from "@ngneat/transloco";

export type CourseDesignStatus = ('active'|'inactive');

export class CourseDesign {
    id?: number;
    design?: string;
    name?: string;
    url?: string;
    contract?: string;
    position?: number;
    status?: CourseDesignStatus;
    parent?: CourseDesign;

    static fromJson(fields?: any): CourseDesign {
        let item = Object.assign(new this(), fields);
        item.parent = item?.parent ? CourseDesign.fromJson(item?.parent) : null;
        return item;
    }

    static getStatusTitle(status: CourseDesignStatus = 'active')
    {
        const statuses: {[name in CourseDesignStatus]?: string} = {
            'active': translate('Видим'),
            'inactive': translate('Скрит')
        };

        return statuses[status] ?? '';
    }
}
