import { Component, Input, OnInit } from '@angular/core';
import { Certificate } from '../../models/certificate/certificate.model';

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
    @Input() items = [];
    @Input() slidesPerPage:number = 1;
    @Input() certificate: Certificate = new Certificate();

    isMultiple: boolean = false;
    screenWidth: number|null = null;
    slides:any = [];

    constructor() {}

    ngOnInit(): void {
        this.checkScreenWidth();
        if(!this.isMultiple) this.slidesPerPage = 1;
        this.chunk(this.slidesPerPage);
    }

    chunk( splitSize: number) {
        this.items = [].concat(this.items);
        while (this.items.length  ) {
            this.slides.push(this.items.splice(0, (splitSize)));
        }
        return this.slides.pop();
    }

    protected checkScreenWidth(): void {
        this.screenWidth = window.innerWidth ?? 1500;
        this.isMultiple = this.screenWidth > 1500;
    }
}
