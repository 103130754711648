<nav aria-label="Pagination" *ngIf="isVisible">
    <ul class="pagination my-3 d-none d-md-flex">
        <li class="page-item" [ngClass]="{disabled: current <= 1}" *ngIf="showFirst" (click)="onSelect($event, 1)">
            <a class="page-link" href="#" :aria-label="titles.first" [title]="titles.first" (click)="onEmptySelect($event)">
                <span aria-hidden="true"><i class="fa fa-fw fa-angle-double-left fa-xs"></i></span>
                <span class="sr-only">{{ titles.first }}</span>
            </a>
        </li>

        <li class="page-item" [ngClass]="{disabled: current <= 1}" *ngIf="showPrevious" (click)="onSelect($event, current - 1)">
            <a class="page-link" href="#" :aria-label="titles.previous" [title]="titles.previous" (click)="onEmptySelect($event)">
                <span aria-hidden="true"><i class="fa fa-fw fa-angle-left fa-xs"></i></span>
                <span class="sr-only">{{ titles.previous }}</span>
            </a>
        </li>

        <li *ngFor="let index of pagesList"
            class="page-item"
            [ngClass]="{active: index === current}"
            (click)="onSelect($event, index)"
        >
            <a class="page-link" href="#" (click)="onEmptySelect($event)">{{ index }}</a>
        </li>

        <li class="page-item" [ngClass]="{disabled: current >= pages}" *ngIf="showNext" (click)="onSelect($event, current + 1)">
            <a class="page-link" href="#" :aria-label="titles.next" [title]="titles.next" (click)="onEmptySelect($event)">
                <span aria-hidden="true"><i class="fa fa-fw fa-angle-right fa-xs"></i></span>
                <span class="sr-only">{{ titles.next }}</span>
            </a>
        </li>

        <li class="page-item" [ngClass]="{disabled: current >= pages}" *ngIf="showLast" (click)="onSelect($event, pages)">
            <a class="page-link" href="#" :aria-label="titles.last" [title]="titles.last" (click)="onEmptySelect($event)">
                <span aria-hidden="true"><i class="fa fa-fw fa-angle-double-right fa-xs"></i></span>
                <span class="sr-only">{{ titles.last }}</span>
            </a>
        </li>
    </ul>

    <ul class="pagination my-3 d-flex d-md-none">
        <li class="page-item" [ngClass]="{disabled: current <= 1}" *ngIf="showFirst" (click)="onSelect($event, 1)">
            <a class="page-link" href="#" :aria-label="titles.first" [title]="titles.first" (click)="onEmptySelect($event)">
                <span aria-hidden="true"><i class="fa fa-fw fa-angle-double-left fa-xs"></i></span>
                <span class="sr-only">{{ titles.first }}</span>
            </a>
        </li>

        <li class="page-item" [ngClass]="{disabled: current <= 1}" *ngIf="showPrevious" (click)="onSelect($event, current - 1)">
            <a class="page-link" href="#" :aria-label="titles.previous" [title]="titles.previous" (click)="onEmptySelect($event)">
                <span aria-hidden="true"><i class="fa fa-fw fa-angle-left fa-xs"></i></span>
                <span class="sr-only">{{ titles.previous }}</span>
            </a>
        </li>

        <li class="page-item" [ngClass]="{disabled: current >= pages}" *ngIf="showNext" (click)="onSelect($event, current + 1)">
            <a class="page-link" href="#" :aria-label="titles.next" [title]="titles.next" (click)="onEmptySelect($event)">
                <span aria-hidden="true"><i class="fa fa-fw fa-angle-right fa-xs"></i></span>
                <span class="sr-only">{{ titles.next }}</span>
            </a>
        </li>

        <li class="page-item" [ngClass]="{disabled: current >= pages}" *ngIf="showLast" (click)="onSelect($event, pages)">
            <a class="page-link" href="#" :aria-label="titles.last" [title]="titles.last" (click)="onEmptySelect($event)">
                <span aria-hidden="true"><i class="fa fa-fw fa-angle-double-right fa-xs"></i></span>
                <span class="sr-only">{{ titles.last }}</span>
            </a>
        </li>
    </ul>

</nav>
