<div class="payments mb-4" *ngIf="!hide">
    <h3>
        {{ headline ?? ('Събития' | transloco) }}

        <div class="separator third d-flex align-items-center mt-3">
            <span></span>
            <i class="fa-solid fa-ellipsis mx-2"></i>
        </div>
    </h3>

    <calendar   [groupId]="group?.id ?? null"
                mode="view"
                variant="embed"
                class="mt-4 d-block"
    ></calendar>

    <div class="text-end mt-3 mb-2">
        <a [routerLink]="'/calendar/' + group?.id" class="btn btn-primary ">
            {{ 'Виж събития' | transloco }}
        </a>
    </div>
</div>
