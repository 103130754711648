
<div class="payment d-flex h-100 align-items-center justify-content-between" (click)="onPayment($event)">
    <div class="headline col-xl col-lg-auto col-sm col-xs-auto" [class.py-2]="!payment?.group?.course?.title?.length || !payment?.due?.length">
        <h4 class="pt-2 px-3 mb-1 course-title">{{payment?.group?.course?.title }}</h4>
        <p class="pb-2 px-3 course-date">{{payment?.due | dateLocale:"d MMM YYYY"}}</p>
    </div>
    <div class="amount col-xl col-auto align-self-stretch d-flex align-items-center" [class.paid]="payment?.status === 'paid'">
        <span class="m-auto px-2" *ngIf="!withName">
            <ng-container *ngIf="displayPayText">
                {{ 'Плати' | transloco }}
            </ng-container>
            {{ payment?.price?.bgn | price }}
        </span>

        <span class="m-auto px-2 text-center" *ngIf="withName">
            <div *ngIf="payment?.user">{{ payment?.user | userName }}</div>
            <small class="fw-normal">{{payment?.price?.bgn | price }}</small>
        </span>
    </div>
</div>
