import { AfterContentInit, Component, ContentChildren, Input, OnDestroy, OnInit, QueryList, TemplateRef } from '@angular/core';
import { SectionDirective } from '../../directives/section.directive';
import { FranchiseService } from '../../services/franchise.service';
import { Subscription } from 'rxjs';
import { Franchise } from '../../models/franchise.model';

@Component({
    selector: 'page-auth',
    templateUrl: './page-auth.component.html',
    styleUrls: ['./page-auth.component.scss']
})
export class PageAuthComponent implements OnInit, OnDestroy, AfterContentInit {
    private subscriptions: Subscription[] = [];

    @ContentChildren(SectionDirective) templates?: QueryList<any>;

    content: TemplateRef<any>|null = null;
    company: Franchise|null = null;

    @Input() logoLinkToPortal: boolean = false;
    @Input() forceShrinkWidth: boolean = false;
    @Input() loading: boolean = false;

    constructor(
        private franchiseService: FranchiseService,
    ) {
        const subscription = this.franchiseService.getCompanyDetails().subscribe(data => {
            this.company = data;
        });
        this.subscriptions.push(subscription);
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.subscriptions?.map(subscription => subscription?.unsubscribe());
    }

    ngAfterContentInit() {
        this.templates?.forEach((item) => {
            switch(item.getType()) {
                case 'content':
                    this.content = item.template;
                    break;

                default:

                    break;
            }
        });
    }
}
