import { Group } from "../group.model";
import { User } from "../user/user.model";

export class Certificate {
    id?: number;
    uuid?: string;
    url?: string;
    preview?: string;
    group?: Group;
    user?: User;
    number?: string;
    created?: string;
    thumbnails?: string[];

    static fromJson(fields: any): Certificate {
        let item = Object.assign(new this(), fields);

        item.created = new Date(fields?.created);
        item.group = Group.fromJson(fields?.group);
        item.user = User.fromJson(fields?.user);

        return item;
    }



}
