import { CourseCategoryTreeCourse } from "./course-category-tree-category.model";

export type CourseCategoryTreeStatus = ('active'|'inactive');

export class CourseCategoryTree {
    id?: number;
    name?: string;
    design?: string;
    url?: string;
    position?: number;
    status?: CourseCategoryTreeStatus;
    children?: CourseCategoryTree[];
    courses?: CourseCategoryTreeCourse[];

    static fromJson(fields?: any): CourseCategoryTree {
        let item = Object.assign(new this(), fields);
        item.children = item?.children?.map((item: any) => CourseCategoryTree.fromJson(item));
        item.courses = item?.courses?.map((item: any) => CourseCategoryTreeCourse.fromJson(item));
        return item;
    }
}
