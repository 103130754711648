import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { GroupService } from '../../services/group.service';
import { Payment } from '../../models/payment.model';

@Component({
    selector: 'card-payment',
    templateUrl: './card-payment.component.html',
    styleUrls: ['./card-payment.component.scss']
})
export class CardPaymentComponent implements OnInit, OnDestroy {
    protected subscriptions: Subscription[] = [];

    @Input() payment: Payment|null = null;
    @Input() withName: boolean = false;
    @Input() displayPayText: boolean = true;

    @Output() onClick: EventEmitter<Event> = new EventEmitter();

    loading: boolean = false;

    constructor(
        protected router: Router,
        protected paymentService: PaymentService,
        protected GroupService: GroupService,
    ) { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(subscription => subscription?.unsubscribe());
    }

    onPayment(event: Event): void {
        event?.preventDefault();
        this.onClick.emit(event);
    }
}
