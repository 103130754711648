export type NoticeType = ('modal'|'banner');
export type NoticeStatus = ('active'|'inactive');

export class Notice {
    id?: number;
    type?: NoticeType;
    title?: string;
    description?: string;
    link?: string;
    filename?: string;
    path?: string;
    mime?: string;
    size?: number;
    url?: string;
    status?: NoticeStatus;
    start_date?: string;
    end_date?: string
    created?: Date;
    updated?: Date;


    static fromJson(fields: any): Notice {
        let item = Object.assign(new this(), fields);

        return item;
    }
}
