
export class Methodology {
    id?: number;
    title?: string;
    duration?: number;
    type?: string;
    content?: string;
    status?: string;

    static fromJson(fields: any): Methodology {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
