<div class="tabs"
     [ngClass]="{
         'd-none d-lg-block': fallbackToAccordion,
         'orange': variant === 'orange',
         'default': variant === 'default'
     }"
>
    <mat-tab-group
                    animationDuration="0ms"
                    infinite-scroll
                    [infiniteScrollDistance]="2"
                    [selectedIndex]="selectedTabIndex"
                    (selectedIndexChange)="onTabSelectChanged($event)"
                    [class.full-width]="fullWidth"
                    [class.toolbar-hidden]="hideToolbar"
    >
        <mat-tab *ngFor="let tab of tabs; let index = index" [label]="tab.label">
            <ng-container *ngIf="selectedTabIndex === index && toggler === 'tab' && showTabContents"
                          [ngTemplateOutlet]="contents"
                          [ngTemplateOutletContext]="{tab}">
            </ng-container>
        </mat-tab>
    </mat-tab-group>
</div>


<div *ngIf="fallbackToAccordion"
     class="tabs-mobile d-lg-none"
     [ngClass]="{
         'orange': variant === 'orange',
         'default': variant === 'default'
     }"
>
    <div class="accordion accordion-flush"
        [id]="'tabAccordion' + unique"
        (show.bs.collapse)="onAccordionToggle($event, 'show')"
        (hide.bs.collapse)="onAccordionToggle($event, 'hide')"
    >
        <div class="accordion-item" *ngFor="let tab of tabs; let index = index" [attr.data-index]="index">
            <h2 class="accordion-header" [id]="'tabAccordionHeader' + unique + index" [class.d-none]="hideToolbar">
                <button class="accordion-button"
                        [class.collapsed]="selectedTabIndex != index"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#tabAccordionTarget' + unique + index"
                        aria-expanded="false"
                        [attr.aria-controls]="'tabAccordionTarget' + unique + index">
                    <span class="m-sm-auto">{{ tab.label }}</span>
                </button>
            </h2>
            <div [id]="'tabAccordionTarget' + unique + index"
                    class="accordion-collapse collapse"
                    [class.show]="selectedTabIndex == index"
                    [attr.aria-labelledby]="'tabAccordionHeader' + unique + index"
                    [attr.data-bs-parent]="'#tabAccordion' + unique">
                <div class="accordion-body">
                    <ng-container *ngIf="selectedTabIndex === index && toggler === 'accordion' && showTabContents"
                                  [ngTemplateOutlet]="contents"
                                  [ngTemplateOutletContext]="{tab}">
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #contents let-tab="tab">
    <ng-content></ng-content>
    <ng-container [ngTemplateOutlet]="tab?.content ?? null"></ng-container>
</ng-template>
