import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, PLATFORM_ID, Inject } from '@angular/core';
import { CalendarOptions, DateSelectArg, DatesSetArg, EventClickArg, EventInput, EventSourceInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { MessageService } from 'primeng/api';
import { translate } from '@ngneat/transloco';

import { CalendarService } from '../../services/calendar.service';
import { Group } from '../../models/group.model';
import { GroupService } from '../../services/group.service';
import { environment } from 'src/environments/environment';
import { EventItem } from '../../models/event/event-item.model';
import { Event } from '../../models/event/event.model';
import { isPlatformServer } from '@angular/common';

type EventInputItem = EventInput & {
    details: Event;
    type?: ('holiday'|'program');
};

export interface CalendarComponentRangeEvent {
    start: Date;
    end: Date;
};

export type StartOf = ('year' | 'month' | 'quarter' | 'week' | 'isoWeek' | 'day' | 'date' | 'hour' | 'minute' | 'second');

@Component({
    selector: 'calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
    protected readonly CACHING_MAX_TIME: number = environment.caching.default;

    protected subscriptions: Subscription[] = [];
    protected innerGroupId: number|null = null;
    protected innerMode: ('view'|'edit') = 'view';
    protected innerLoadingQueue: number = 0;
    protected innerRefresh: boolean = false;

    @Input() variant: ('default'|'embed') = 'default';

    @Input()
    set mode(val: ('view'|'edit')) {
        if (this.innerMode !== val) {
            this.innerMode = val;

            if (this.calendarOptions) {
                const editable = this.innerMode === 'edit';

                this.calendarOptions.selectable = editable;
                this.calendarOptions.showNonCurrentDates = editable;
                this.calendarOptions.fixedWeekCount = editable;
            }
        }
    }
    get mode() {
        return this.innerMode;
    }

    @Input() defaultView: ('dayGridMonth'|'timeGridWeek'|'timeGridDay') = 'dayGridMonth';
    @Input() userId: number|null = null;
    @Input() className: string | null = null;
    @Input()
    set groupId(val: number|null) {
        if (this.innerGroupId !== val) {
            this.innerGroupId = val;
            this.getGroup();
        }
    }
    get groupId() {
        return this.innerGroupId;
    }
    @Output() onGroupLoad: EventEmitter<Group> = new EventEmitter();
    @Output() onRangeSelect: EventEmitter<CalendarComponentRangeEvent> = new EventEmitter();
    @Output() onItemEdit: EventEmitter<EventItem> = new EventEmitter();

    @Input()
    set refresh(val: boolean) {
        if (this.innerRefresh !== val) {
            this.innerRefresh = val;

            if (val) {
                this.getAllEvents(true);
            }
        }
    }
    get refresh(): boolean {
        return this.innerRefresh;
    }
    @Output() refreshChange: EventEmitter<boolean> = new EventEmitter();

    loading: boolean = false;
    group?: Group;
    showDetailsModal: boolean = false;
    showDeleteConfirm: boolean = false;
    dialogContent: string = '';
    content: string = '';
    eventType: string = 'program';
    events: any[] = [];
    eventsForDate: any = [];
    selectedDate: Date|null = null;
    selectedDateEnd: Date|null = null;
    eventId: number|null = null;
    selectedOtherEvents: number[] = [];
    otherEvents: EventItem[] = [];
    range: {
        start?: string,
        end?: string
    } = {};
    calendarOptions: CalendarOptions = {
        plugins: [interactionPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: 'dayGridMonth',
        locale: this.calendarService.getLocale(),
        timeZone: 'local',
        events: [],
        firstDay: 1,
        headerToolbar: {
            left: 'dayGridMonth,timeGridWeek,timeGridDay',
            center: 'title',
            right: 'today prev,next',
        },
        contentHeight: 'auto',

        showNonCurrentDates: this.mode === 'edit',
        fixedWeekCount: this.mode === 'edit',

        selectable: this.mode === 'edit',
        select: (arg: DateSelectArg) => {
            const start = arg.start;
            const end =  moment(arg.end).subtract(1, 'second').toDate();

            this.eventsForDate = this.getEventsForDate(start, end);
            this.showDetailsModal = this.eventsForDate.length > 0;

            if (!this.showDetailsModal) {
                this.onRangeSelect.emit({start, end});
            }
        },
        dateClick: (arg: DateClickArg) => {
            if (this.calendarOptions.selectable) {
                return;
            }

            const clickedDate = arg.date;

            this.eventsForDate = this.getEventsForDate(clickedDate,
                moment(clickedDate).endOf('day').toDate()
            );

            this.showDetailsModal = this.eventsForDate.length > 0;
        },
        eventClick: (arg: EventClickArg) => {
            this.showDetailsModal = true;
            this.dialogContent = arg.event.title;

            const eventId: number = +(arg.event?.id ?? 0);
            this.eventsForDate = this.getEventsForDate(arg.event?.start ?? new Date, arg.event?.end ?? undefined, eventId);
        },
        datesSet: (arg: DatesSetArg) => {
            this.range.start = arg.startStr;
            this.range.end = arg.endStr;
            this.updateViewType(arg.view.type); 
            
            this.getAllEvents();
        },
        eventContent: (info) => {
            const bgColor = info?.event?.backgroundColor ? info?.event?.backgroundColor : '#3C4B66';

            const isShortEvent = moment(info?.event?.end).diff(moment(info?.event?.start), 'minutes') < 60;

            const dateRange = info?.event?.start?.getTime() === info?.event?.end?.getTime()
                ? moment(info?.event?.start).format('HH:mm')
                : [
                    moment(info?.event?.start).format('HH:mm'),
                    moment(info?.event?.end).format('HH:mm')
                ].join(' - ');

            return {
                html: `
                    <div class="fc-content" style="background-color: ${bgColor};">
                        <span class="d-none event-title ${isShortEvent ? 'p-0' : ''}">
                            <div class="date-range ${isShortEvent ? 'date-range-custom' : ''}">${dateRange}</div>
                            ${info.event.title}
                        </span>
                    </div>`
            };
        },
    };

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            setTimeout(() => {
                if (this.innerLoadingQueue <= 0) {
                    this.innerLoadingQueue = 0;
                    this.loading = false;
                } else {
                    this.loading = true;
                }
            });
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        protected messageService: MessageService,
        protected calendarService: CalendarService,
        protected groupService: GroupService,
    ) { }

    ngOnInit(): void {
        if (!isPlatformServer(this.platformId)) {
            return;
        }

        this.calendarOptions.initialView = this.defaultView;
        const subscription = this.calendarService.onLocaleChanged().subscribe(data => {
            data && (this.calendarOptions.locale = data);
        });
        this.subscriptions.push(subscription);
    }

    ngOnDestroy(): void {
        this.subscriptions?.forEach((item) => item?.unsubscribe());
    }

    getAllEvents(force: boolean = false) {
        let groups: number = 0;

        const ranges: { [key: string]: StartOf } = {
            dayGridMonth: "month",
            timeGridWeek: "week",
            timeGridDay: "day"
        };

        const currentView = this.calendarOptions.initialView ?? 'dayGridMonth';
        const rangeType = ranges[currentView] ?? 'month';

        const start = this.range?.start ? moment(this.range.start).startOf(rangeType).toISOString() : '';
        const end = this.range?.end ? moment(this.range.end).startOf(rangeType).toISOString() : '';
    
        const indexFilter = {
            groups: [this.groupId ? '' + this.groupId : ''],
            start,
            end,
            ...(this.userId ? {
                user:this.userId
            } : {}),
        };

        this.loadingQueue++;
        const subscription = this.calendarService.getList(indexFilter, this.CACHING_MAX_TIME + (force ? .1 : 0 ))
        .subscribe({
            next: ({ data }) => {
                this.loadingQueue--;
                this.refreshChange.emit(false);

                const events: EventInputItem[] = [];

                data.forEach(group => {
                    groups++;

                    group?.events?.forEach((event: EventItem) => {
                        const bgColor = this.getBackgroundColor(groups);

                        events?.push({
                            details: group,
                            type: event.type,

                            id: '' + event.id,
                            start: event.start,
                            end: event.end,
                            title: event.title,

                            backgroundColor: this.eventType === 'program' ? bgColor : '#f72819',
                        });
                    });
                });

                this.calendarOptions.events = events;
            },
            error: error => {
                this.loadingQueue--;
                this.refreshChange.emit(false);
            }
        });
        this.subscriptions.push(subscription);
    }

    getEventsForDate(date: Date, dateTo?: Date, eventId?: number): any[] {
        this.selectedDate = date;
        this.selectedDateEnd = dateTo ?? null;

        if (!this.calendarOptions || !this.calendarOptions.events) {
            return [];
        }

        const eventsArray: any[] = Array.isArray(this.calendarOptions.events)
            ? this.calendarOptions.events
            : [this.calendarOptions.events];

        let events = [];

        if (eventId && eventId > 0) {
            events = eventsArray.filter(course => +course?.id === eventId);
        } else {
            events = eventsArray.filter((event) => {
                let eventFrom = moment(event.start).utc().toDate();
                let eventTo = moment(event.end).utc().subtract(1, 'second').toDate();

                if (eventTo?.getTime() < eventFrom?.getTime()) {
                    [eventFrom, eventTo] = [eventTo, eventFrom];
                }

                const clickedDateFrom = date;
                const clickedDateEnd = dateTo ?? date;

                const match =
                    eventFrom <= clickedDateEnd
                    && eventTo >= clickedDateFrom;

                return match;
            });
        }

        return events.sort((a, b) =>
            (new Date(a.start).getTime() - new Date(b.start).getTime())
            || (new Date(a.end).getTime() - new Date(b.end).getTime())
            || a.title.localeCompare(b.title)
        );
    }

    getBackgroundColor(count: number): string {
        const colors = [
            '#3C4B66',
            '#F87300',
            '#FFBB01',
            '#5078BE',
            '#8dbc21',
            '#6c757d',
            '#198754',
            '#0dcaf0',
            '#c5007e',
            '#FA8072',
        ];
        const index = count % colors.length;

        return colors[index - 1] ?? colors[0];
    }

    getGroup() {
        if (!this.groupId) {
            return;
        }
        const subscription = this.groupService.getGroup(this.groupId).subscribe(response => {
            this.group = response.data;
            this.onGroupLoad.emit(this.group);
        });
        this.subscriptions.push(subscription);
    }

    onAdd(event: MouseEvent): boolean {
        event?.preventDefault();
        this.onRangeSelect.emit({
            start: this.selectedDate ?? (new Date()),
            end: this.selectedDateEnd ?? moment(this.selectedDate).endOf('day').subtract(1, 'second').toDate() ?? (new Date())
        });
        this.showDetailsModal = false;
        return false;
    }

    onDelete(event: MouseEvent, eventItem: EventSourceInput & {id: number, details: EventItem}): boolean {
        event?.preventDefault();

        this.eventId = eventItem.id ?? null;
        this.showDetailsModal = false;
        this.showDeleteConfirm = true;

        if (eventItem?.details?.id) {
            this.loadingQueue++;
            this.otherEvents = [];
            this.selectedOtherEvents = [];

            const subscription = this.calendarService.getList({
                groups: eventItem.details?.id + '',
                start: moment().startOf('year').toISOString(),
                end: moment().endOf('year').toISOString(),
            }).subscribe({
                next: response => {
                    this.otherEvents = (response.data[0]?.events ?? [])?.filter(item => item.id !== eventItem.id)?.sort((a, b) => {
                        let compare = moment(a.start).unix() - moment(b.start).unix();
                        if (!compare) {
                            return a.title?.localeCompare(b.title ?? '') ?? 0;
                        }
                        return compare ?? 0;
                    });
                    this.loadingQueue--;
                },
                error: error => {
                    this.loadingQueue--;
                }
            });
            this.subscriptions.push(subscription);
        }

        return false;
    }

    onEdit(event: MouseEvent, eventItem: EventItem): boolean {
        event?.preventDefault();
        this.onItemEdit.emit(eventItem);
        this.showDetailsModal = false;
        return false;
    }

    onDeleteConfirmed(action: string): void {
        this.showDeleteConfirm = false;

        if (action === 'yes' && this.eventId) {
            if (this.selectedOtherEvents?.length) {
                this.loadingQueue++;
                const subscription = this.calendarService.deleteMultiple([this.eventId, ...this.selectedOtherEvents]).subscribe({
                    next: data => {
                        this.loadingQueue--;

                        this.messageService.add({
                            severity: 'success',
                            detail: translate('Успешно изтрито събитие!'),
                        });


                        // NOTE: Unreliable way to remove old records, because API itself is cached and some items could re-appeared again
                        // this.calendarOptions.events = (this.calendarOptions.events as any[])
                        //    .filter(item => this.selectedOtherEvents?.indexOf(item.id) < 0 && item.id !== this.eventId);

                        this.getAllEvents(true);
                    },
                    error: error => {
                        this.loadingQueue--;
                        this.messageService.add({
                            severity: 'error',
                            detail: translate('Възникна грешка при изтриване на събитие!'),
                        });
                    }
                });
                this.subscriptions.push(subscription);
            }
            else {
                this.loadingQueue++;
                const subscription = this.calendarService.delete(this.eventId).subscribe({
                    next: data => {
                        this.loadingQueue--;

                        this.messageService.add({
                            severity: 'success',
                            detail: translate('Успешно изтрито събитие!'),
                        });

                        this.calendarOptions.events = (this.calendarOptions.events as any[]).filter(item => item.id !== this.eventId);
                    },
                    error: error => {
                        this.loadingQueue--;
                        this.messageService.add({
                            severity: 'error',
                            detail: translate('Възникна грешка при изтриване на събитие!'),
                        });
                    }
                });
                this.subscriptions.push(subscription);
            }
        }
    }

    onDialogToggle(event: any): void {
        this.refreshEvents();
    }

    refreshEvents(): void {
        setTimeout(() => {
            if (Array.isArray(this.calendarOptions?.events)) {
                this.calendarOptions.events = this.calendarOptions?.events?.slice();
            }
        }, 0);
    }

    updateViewType(newViewType: string) {
        if (this.calendarOptions.initialView !== newViewType) {
            this.calendarOptions.initialView = newViewType;
            this.getAllEvents(); 
        }
    }
    
}
