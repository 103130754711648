import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Event } from 'src/app/shared/models/event/event.model';

import { User } from 'src/app/shared/models/user/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CalendarService, CalendarServiceIndex } from 'src/app/shared/services/calendar.service';
import { environment } from 'src/environments/environment';

interface EventItem {
    id: number;
    timestamp: number;
    day_of_week: string;
    date: string;
    course: string;
    title: string;
    hours_range: string;
    url: string|null;
};

@Component({
    selector: 'sidebar-upcoming-courses',
    templateUrl: './upcoming-courses.component.html',
    styleUrls: ['./upcoming-courses.component.scss']
})
export class SidebarUpcomingCoursesComponent implements OnInit, OnDestroy {
    protected readonly CACHING_MAX_TIME: number = environment.caching.default;
    protected readonly DEFAULT_LIMIT_ITEMS: number = 3;

    protected subscriptions: Subscription[] = [];
    protected innerGroup: number|null = null;
    protected innerLoadingQueue: number = 0;

    events?: EventItem[] = [];
    user?: User|null;
    loading: boolean = false;

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
            } else {
                this.loading = true;
            }
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    @Input() limit: number = this.DEFAULT_LIMIT_ITEMS;

    @Input()
    set group(val: number|null) {
        if (this.innerGroup !== val) {
            this.innerGroup = val;

            if (this.events?.length) {
                this.events = this.events?.map(event => {
                    event.url = event?.id !== val ? '/groups/' + event?.id : null;
                    return event;
                })
            }
        }
    }
    get group(): number|null{
        return this.innerGroup;
    }



    constructor(
        private authService: AuthService,
        private calendarService: CalendarService,
    ) { }

    ngOnInit(): void {
        this.user = this.authService.getUser();

        if (this.user?.can(['student', 'teacher'])) {
            this.getEvents();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(subscription => subscription?.unsubscribe());
    }

    getEvents(force: boolean = false) {
        const start = moment().startOf('hour').toISOString() ?? '';
        const end = moment(start).add(1, 'month').toISOString() ?? '';

        this.loadingQueue++;
        const subscription = this.calendarService.getList({
                groups: [],
                start,
                end,
                user: this.user?.id
            },
            this.CACHING_MAX_TIME + (force ? .1 : 0 )
        ).subscribe({
            next: response => {
                this.loadingQueue--;
                this.events = response.data.map(group => {
                    return group?.events?.map(event => {
                        const start = moment(event?.start);
                        const end = moment(event?.end);

                        const result: EventItem = {
                            id: group?.id ?? 0,
                            timestamp: start?.unix() ?? -1,
                            day_of_week: start?.format("dddd"),
                            date: start.format("DD.MM.YYYY"),
                            course: group?.course?.title ?? '',
                            title: event?.title ?? '',
                            hours_range: [
                                start.format('HH:mm'),
                                end.format('HH:mm')
                            ].join(' - '),
                            url: group?.id !== this.group ? '/groups/' + group?.id : null,
                        };

                        return result;
                    }) as EventItem[];
                }).reduce((acc, current) => acc?.concat(current ?? []), [])
                .filter(item => item?.timestamp > 0)
                .sort((a, b) => a.timestamp - b.timestamp)
                .slice(0, this.limit);
            },
            error: error => {
                this.loadingQueue--;
            }
        });
        this.subscriptions.push(subscription);
    }
}
