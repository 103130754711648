import { TheoryExample } from "./theory-example.model";

export class Theory {
    id?: number;
    title?: string;
    status?: string;
    examples?: TheoryExample[];

    static fromJson(fields: any): Theory {
        let item = Object.assign(new this(), fields);
        item.examples = fields?.examples?.map((example: any) => TheoryExample.fromJson(example));
        return item;
    }
}
