import { Franchise } from "../franchise.model";

export class ZoomLive {
    id?: number;
    title?: string;
    number?: number;
    password?: string;
    apiKey?: string;
    sdkKey?: string;
    sdkSignature?: string;
    /**
     * @deprecated
     */
    signature?: string;
    /**
     * @deprecated
     */
    jwt?: string;

    static fromJson(fields: any): ZoomLive {
        let item = Object.assign(new this(), fields);
        item.number = Number(fields.number);
        item.apiKey = fields.api_key ?? '';
        item.sdkKey = fields.sdk_key ?? '';
        item.sdkSignature = fields.sdk_signature ?? '';
        return item;
    }
};
