<div class="avatar-wrapper mx-2" *ngIf="type === 'drop' || type === 'dropAlt'">
    <div class="drop-avatar" [ngClass]="{
        'tiny': size === 'tiny',
        'small': size === 'small',
        'smaller': size === 'smaller',
        'medium': size === 'medium',
        'large': size === 'large',
        'big': size === 'big',
        'huge': size === 'huge',
        'empty': !user?.id && !photo && showEmpty,
        'with-shadow': withShadow,
        'highlight': highlight,
        'blue': type === 'dropAlt',
        'rotating': loading
    }">
        <ng-container [ngTemplateOutlet]="avatar"></ng-container>
    </div>
</div>

<ng-container *ngIf="type === 'circle'">
    <ng-container [ngTemplateOutlet]="avatar"></ng-container>
</ng-container>
<ng-template #avatar>
    <div class="avatar {{ className }}" [ngClass]="{
        'tiny': type === 'circle' && size === 'tiny',
        'small': type === 'circle' && size === 'small',
        'smaller': type === 'circle' && size === 'smaller',
        'medium': type === 'circle' && size === 'medium',
        'large': type === 'circle' && size === 'large',
        'big': type === 'circle' && size === 'big',
        'huge': type === 'circle' && size === 'huge',
        'empty': !user?.id && !photo && showEmpty,
        'with-shadow': withShadow
    }">
        <small *ngIf="(!user?.image || imageError) && !photo" class="avatar-initial">
            {{initials}}
        </small>

        <small *ngIf="!user?.id && (!photo || imageError || !imageLoaded)"
                class="text-uppercase text-white text-nowrap" [attr.aria-label]="user?.name || 'User'">{{ initials }}</small>

        <img *ngIf="(photo || user?.image?.length) && !imageError"
             [src]="(photo || user?.image) + (urlWidth && urlResize ? '?w=' + urlWidth : '')"
             class="h-100 w-100"
             [alt]="user?.name ?? 'User'"
             [class.d-none]="!imageLoaded"
             (load)="imageLoaded = true"
             (error)="imageError = true"
        />

        <i class="selected fa-solid fa-check fa-fw fa-4x text-success" *ngIf="selected"></i>

        <i *ngIf="enableRemoving && (photo?.length || user?.image?.length)"
            class="fa fa-fw fa-times text-danger remove-photo"
            [title]="removingTitle"
            (click)="onRemoveClicked($event)"></i>

        <ng-content></ng-content>

        <span *ngIf="status === true || status === false"
              class="status rounded-circle align-middle mr-1 position-absolute"
              [class.bg-success]="status === true"
              [class.bg-secondary]="status === false"
              [attr.title]="status === true ? 'Online' : status === false ? 'Offline' : null"
        ></span>
    </div>
</ng-template>
