<p-dialog
    [visible]="true"
    (visibleChange)="onCloseAction($event)"
    [closable]="true"
    [draggable]="false"
    [modal]="true"
    [style]="{'min-width': '40vw', 'width': '991px', 'max-width': '90vw'}"
    styleClass="mx-3 mx-sm-0"
    [header]="('Добавяне на курсист' | transloco)"

>
    <alert [type]="messageType" [message]="message" (remove)="message = ''"></alert>

    <form *ngIf="form" (submit)="onSubmit($event)" [formGroup]="form" class="profile-form standard-form mb-2" autocomplete="off">

        <div class="card border-0 mb-2">
            <div class="card-body">
                <div class="col-12 text-start mb-3">
                    <upload-avatar (onFinished)="onFileFinished($event)"
                                (onRemove)="photo = {}"
                                [withRemove]="true"
                                [avatar]="photo"
                                [user]="student">
                    </upload-avatar>

                </div>

                <div class="col-12 mb-4" *ngIf="user && (user | roleAny: 'admin')">
                    <div class="form-group">
                        <label for="franchise">{{ 'Франчайз:' }}</label>
                        <p-dropdown requiredField requiredFieldAttribute="franchise"
                                    inputId="franchise"
                                    [class.is-invalid]="errors?.franchise?.status === 'INVALID'"
                                    [filter]="true"
                                    [options]="franchisesList"
                                    formControlName="franchise"
                                    optionValue="id"
                                    optionLabel="name"
                                    inputId="franchise"
                                    class="dropdown my-2 w-100"
                                    styleClass="w-100"
                                    appendTo="body"
                                    [emptyFilterMessage]="'Няма намерен резултат' | transloco"
                        ></p-dropdown>
                        <div class="invalid-feedback" *ngIf="errors?.franchise?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.franchise?.errors?.any">{{ errors?.franchise?.errors?.any }}</div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="row">
                        <div class="col-md-4 mb-2">
                            <div class="form-group">
                                <label for="name">{{ 'Име:' | transloco }}</label>
                                <input requiredField
                                        type="text"
                                        id="name"
                                        class="form-control my-2"
                                        name="name"
                                        placeholder="Иван"
                                        formControlName="name"
                                        [attr.disabled]="loading ? true : null"
                                        [class.is-invalid]="errors?.name?.status === 'INVALID'"
                                />
                                <div class="invalid-feedback" *ngIf="errors?.name?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.name?.errors?.minlength && !errors?.name?.errors?.required">{{ 'Полето не може да е по-малко от 2 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.name?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.name?.errors?.any">{{ errors?.name?.errors?.any }}</div>
                            </div>
                        </div>

                        <div class="col-md-4 mb-2">
                            <div class="form-group">
                                <label for="surname">{{ 'Презиме:' | transloco }}</label>
                                <input requiredField
                                        type="text"
                                        id="surname"
                                        class="form-control my-2"
                                        name="surname"
                                        placeholder="Иванов"
                                        formControlName="surname"
                                        [attr.disabled]="loading ? true : null"
                                        [class.is-invalid]="errors?.surname?.status === 'INVALID'"
                                />
                                <div class="invalid-feedback" *ngIf="errors?.surname?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.surname?.errors?.minlength && !errors?.surname?.errors?.required">{{ 'Полето не може да е по-малко от 2 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.surname?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.surname?.errors?.any">{{ errors?.surname?.errors?.any }}</div>
                            </div>
                        </div>

                        <div class="col-md-4 mb-2">
                            <div class="form-group">
                                <label for="lastname">{{ 'Фамилия:' | transloco }}</label>
                                <input requiredField
                                        type="text"
                                        id="lastname"
                                        class="form-control my-2"
                                        name="lastname"
                                        placeholder="Иванов"
                                        formControlName="lastname"
                                        [attr.disabled]="loading ? true : null"
                                        [class.is-invalid]="errors?.lastname?.status === 'INVALID'"
                                />
                                <div class="invalid-feedback" *ngIf="errors?.lastname?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.lastname?.errors?.minlength && !errors?.lastname?.errors?.required">{{ 'Полето не може да е по-малко от 2 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.lastname?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.lastname?.errors?.any">{{ errors?.lastname?.errors?.any }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-md-4 mb-2">
                            <div class="form-group">
                                <label for="inputEmail">{{ 'E-mail:' | transloco }}</label>
                                <input  requiredField
                                        type="text"
                                        autocomplete="off"
                                        aria-autocomplete="none"
                                        class="form-control my-2"
                                        id="inputEmail"
                                        [attr.disabled]="loading ? true : null"
                                        formControlName="email"
                                        placeholder="contact@example.com"
                                        [class.is-invalid]="errors?.email?.status === 'INVALID'"
                                />
                                <div class="invalid-feedback" *ngIf="errors?.email?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.email?.errors?.notunique">{{ 'Адресът е зает' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.email?.errors?.minlength && !errors?.email?.errors?.required">{{ 'Полето не може да е по-малко от 2 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.email?.errors?.pattern || errors?.email?.errors?.email">{{ 'Недостатъчно символи' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.email?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.email?.errors?.any">{{ errors?.email?.errors?.any }}</div>
                            </div>
                        </div>

                        <div class="col-md-4 mb-2">
                            <div class="form-group">
                                <label for="inputPassword">{{ 'Парола:' | transloco }}</label>
                                <input  requiredField
                                        type="password"
                                        autoComplete="new-password"
                                        aria-autocomplete="none"
                                        class="form-control my-2"
                                        id="inputPassword"
                                        [attr.disabled]="loading ? true : null"
                                        formControlName="password"
                                        placeholder=""
                                        [class.is-invalid]="errors?.password?.status === 'INVALID'"
                                />
                                <div class="invalid-feedback" *ngIf="errors?.password?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.password?.errors?.minlength && !errors?.password?.errors?.required">{{ 'Полето не може да е по-малко от 8 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.password?.errors?.any">{{ errors?.password?.errors?.any }}</div>
                            </div>
                        </div>

                        <div class="col-md-4 mb-2">
                            <div class="form-group">
                                <label for="inputPhone">{{ 'Телефон:' | transloco }}</label>
                                <input requiredField
                                        type="text"
                                        class="form-control my-2"
                                        id="inputPhone"
                                        [attr.disabled]="loading ? true : null"
                                        formControlName="phone"
                                        placeholder="+359 123-456-789"
                                        [class.is-invalid]="errors?.phone?.status === 'INVALID'"
                                />
                                <div class="invalid-feedback" *ngIf="errors?.phone?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.phone?.errors?.minlength && !errors?.phone?.errors?.required">{{ 'Полето не може да е по-малко от 2 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.phone?.errors?.maxlength">{{ 'Не може да е повече от 20 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.phone?.errors?.any">{{ errors?.phone?.errors?.any }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card border-0 mb-2">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="form-group d-flex flex-column">
                            <label for="town">{{ 'Населено място:' | transloco }}</label>
                            <p-dropdown requiredField requiredFieldAttribute="inputId"
                                        inputId="town"
                                        [class.is-invalid]="errors?.town?.status === 'INVALID'"
                                        [filter]="true"
                                        [options]="townList"
                                        formControlName="town"
                                        optionValue="id"
                                        optionLabel="name"
                                        inputId="town"
                                        class="dropdown my-2 w-100"
                                        styleClass="w-100"
                                        appendTo="body"
                                        emptyFilterMessage="Населеното място не е намерено"
                            ></p-dropdown>
                            <div class="invalid-feedback dropdown-feedback" *ngIf="errors?.town?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                            <div class="invalid-feedback dropdown-feedback" *ngIf="errors?.town?.errors?.pattern">{{ 'Невалидна стойност' | transloco }}</div>
                            <div class="invalid-feedback dropdown-feedback" *ngIf="errors?.town?.errors?.any">{{ errors?.active?.errors?.any }}</div>

                        </div>
                    </div>

                    <div class="col-md-6 mb-2">
                        <div class="form-group mb-2">
                            <label for="birthday">{{ 'Дата на раждане:' | transloco }}</label>

                            <p-calendar requiredField requiredFieldAttribute="birthday"
                                        [readonlyInput]="true"
                                        [class.is-invalid]="errors?.birthday?.status === 'INVALID'"
                                        [inputStyleClass]="'form-control w-100 ' + (errors?.birthday?.status === 'INVALID' ? 'is-invalid' : '')"
                                        inputId="birthday"
                                        formControlName="birthday"
                                        [showIcon]="true"
                                        [appendTo]="'body'"
                                        dateFormat="dd.mm.yy"
                                        [showButtonBar]="true"
                                        todayButtonStyleClass="btn btn-sm btn-primary"
                                        clearButtonStyleClass="btn btn-sm btn-primary"
                            ></p-calendar>


                            <div class="invalid-feedback" *ngIf="errors?.birthday?.errors?.required || errors?.birthday?.errors?.date">{{ 'Задължително поле' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.birthday?.errors?.minlength && !errors?.birthday?.errors?.required">{{ 'Полето не може да е по-малко от 2 символа' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.birthday?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.birthday?.errors?.any">{{ errors?.birthday?.errors?.any }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card border-0 mb-2">
            <div class="card-header">
                <h3 class="my-0 fw-bold">{{ 'Родител/настойник (дете-курсист)' | transloco }}</h3>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4 mb-2">
                        <div class="form-group">
                            <label for="parent_name">{{ 'Родител:' | transloco }}</label>
                            <input requiredField
                                    type="text"
                                    id="parent_name"
                                    class="form-control my-2"
                                    name="parent_name"
                                    [placeholder]="'Мария Иванова' | transloco"
                                    formControlName="parent_name"
                                    [attr.disabled]="loading ? true : null"
                                    [class.is-invalid]="errors?.parent_name?.status === 'INVALID'"
                            />
                            <div class="invalid-feedback" *ngIf="errors?.parent_name?.errors?.required">{{ 'Полето е задължително' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.parent_name?.errors?.minlength && !errors?.parent_name?.errors?.required">{{ 'Полето не може да е по-малко от 2 символа' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.parent_name?.errors?.maxlength">{{ 'Не може да е повече от 150 символа' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.parent_name?.errors?.any">{{ errors?.parent_name?.errors?.any }}</div>
                        </div>
                    </div>

                    <div class="col-md-4 mb-2">
                        <div class="form-group">
                            <label for="parent_email">{{ 'E-mail:' | transloco }}</label>
                            <input requiredField
                                    type="text"
                                    id="parent_email"
                                    class="form-control my-2"
                                    name="parent_email"
                                    formControlName="parent_email"
                                    placeholder="contact@example.com"
                                    [attr.disabled]="loading ? true : null"
                                    [class.is-invalid]="errors?.parent_email?.status === 'INVALID'"
                            />
                            <div class="invalid-feedback" *ngIf="errors?.parent_email?.errors?.required">{{ 'Полето не може да е по-малко от 2 символа' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.parent_email?.errors?.pattern || errors?.parent_email?.errors?.email">{{ 'Полето е невалидно' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.parent_email?.errors?.minlength && !errors?.parent_email?.errors?.required">{{ 'Полето не може да е по-малко от 2 символа' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.parent_email?.errors?.maxlength">{{ 'Не може да е повече от 150 символа' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.parent_email?.errors?.any">{{ errors?.parent_name?.errors?.any }}</div>
                        </div>
                    </div>

                    <div class="col-md-4 mb-2">
                        <div class="form-group">
                            <label for="parent_phone">{{ 'Телефон:' | transloco }}</label>
                            <input requiredField
                                    type="text"
                                    id="parent_phone"
                                    class="form-control my-2"
                                    name="parent_phone"
                                    formControlName="parent_phone"
                                    placeholder="+359 123-456-789"
                                    [attr.disabled]="loading ? true : null"
                                    [class.is-invalid]="errors?.parent_phone?.status === 'INVALID'"
                            />
                            <div class="invalid-feedback" *ngIf="errors?.parent_phone?.errors?.required">{{ 'Полето не може да е по-малко от 2 символа' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.parent_phone?.errors?.pattern">{{ 'Полето е невалидно' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.parent_phone?.errors?.minlength && !errors?.parent_phone?.errors?.required">{{ 'Полето не може да е по-малко от 2 символа' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.parent_phone?.errors?.maxlength">{{ 'Не може да е повече от 20 символа' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.parent_phone?.errors?.any">{{ errors?.parent_name?.errors?.any }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <ng-template pTemplate="footer">
        <div class="row">
            <div class="col-12">
                <button type="button"
                        class="btn btn-primary"
                        [disabled]="loading"
                        (click)="onSubmit($event)"
                >
                    <i *ngIf="loading" class="fa-solid fa-spinner fa-fw fa-pulse"></i>
                    <i *ngIf="!loading" class="fa-solid fa-check fa-fw"></i>

                    <span class="mx-2">{{ 'Запиши' | transloco }}</span>
                </button>

                <button type="button"
                        class="btn btn-outline-secondary mx-2"
                        [disabled]="loading"
                        type="button"
                        (click)="onCloseAction($event)"
                >
                    <i class="fa-solid fa-xmark fa-fw"></i>

                    {{ 'Отказ' | transloco }}
                </button>
            </div>
        </div>
    </ng-template>

</p-dialog>
