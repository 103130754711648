export class UserTerm {
    id?: number;
    course?: string;
    group?: string;
    terms?: string;

    static fromJson(fields: any): UserTerm {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
