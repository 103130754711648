import { GroupShort } from "./group-short.model";
import { TestResultAnswer } from "./module-test-result-answer.model";
import { TestPracticeResult } from "./test-practice-result.model";
import { Test } from "./test.model";
import { User } from "./user/user.model";

export class TestUserResult {
    id?: number;
    result?: number;
    exam?: string;
    finished?: string;
    group?: GroupShort;
    test?: Test;
    user?: User;
    final_result?: number;
    answers?: TestResultAnswer[];
    practice?: TestPracticeResult;
    summary?: string;

    static fromJson(fields: any): TestUserResult {
        let item = Object.assign(new this(), fields);

        item.test = Test.fromJson(fields?.test);
        item.user = User.fromJson(fields?.user);
        item.practice = TestPracticeResult.fromJson(fields?.practice);
        item.answers = fields?.answers?.map((item: any) => TestResultAnswer.fromJson(item));
        item.group = GroupShort.fromJson(fields?.group);

        return item;
    }
}
