<p-dialog
    [visible]="true"
    (visibleChange)="onCloseAction($event)"
    [closeOnEscape]="skippable"
    [dismissableMask]="skippable"
    [closable]="closable"
    [draggable]="false"
    [modal]="true"
    [style]="{'min-width': '30vw', 'width': '767px', 'max-width': '90vw'}"
    [class.round-top]="!((headline && headline?.length) || closable) ? true : false"
    styleClass="mx-3 mx-sm-0 confirmDialog"
    [showHeader]="(headline && headline?.length) || closable ? true : false"
    [header]="headline && headline?.length ? headline : '&nbsp;'"

    (onShow)="onShowDialog($event)"
    (onHide)="onHideDialog($event)"
>
    <span [innerHTML]="body" *ngIf="body"></span>

    <ng-content></ng-content>

    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end align-items-center">
            <button *ngFor="let item of actions; let index = index"
                    type="button"
                    [title]="item?.tooltip || ''"
                    class="btn {{ item.btnClass || '' }} px-4"
                    [disabled]="loading"
                    (click)="onButtonClicked($event, item.action)"
            >
                <i class="{{ item.icon }}" *ngIf="item?.icon?.length"></i>
                {{ item.name || '' }}
            </button>
        </div>
    </ng-template>
</p-dialog>
