import * as moment from 'moment';

export class GroupEnrollment {
    price?: number;
    price_online?: number;
    date?: string;

    parsed_date?: Date;

    static fromJson(fields: any): GroupEnrollment {
        let item = Object.assign(new this(), fields);

        const parsedDate = moment.utc(fields?.date, true);
        item.parsed_date = parsedDate?.isValid() ? parsedDate?.toDate() : null;

        return item;
    }
}
