import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { GlobalMessage } from 'src/app/shared/models/global-messages/global-message.model';
import { GlobalMessageService, GlobalMessageServiceIndex } from 'src/app/shared/services/global-message.service';
import { isPlatformServer } from '@angular/common';
import * as moment from 'moment';

@Component({
    selector: 'sidebar-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss']
})
export class SidebarMessageComponent implements OnInit, OnDestroy {
    protected readonly CACHING_MAX_TIME: number = environment.caching.default;
    protected readonly AUTOPLAY_INTERVAL: number = 20000;

    protected subscriptions: Subscription[] = [];

    messages: GlobalMessage[] = [];
    currentIndex: number = 0;
    autoplayInterval: number = 0;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private globalMessageService: GlobalMessageService,
    ) { }

    ngOnInit(): void {
        if (!isPlatformServer(this.platformId)) {
            this.autoplayInterval = this.AUTOPLAY_INTERVAL;
        }

        this.getSystemMessage();
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(subscription => subscription?.unsubscribe());
    }

    protected getSystemMessage(): void {
        const messageFilter: GlobalMessageServiceIndex = {
            expiration: 'active',
            limit: 25,
            sort: [{
                direction: 'asc',
                field: 'status',
            }, {
                direction: 'desc',
                field: 'start',
            }]
        };

        const subscription = this.globalMessageService.getList(messageFilter, this.CACHING_MAX_TIME).subscribe({
            next: data => {
                this.messages = data?.data ?? [];
            },
            error: error => {
                this.messages = [];
            }
        });

        this.subscriptions.push(subscription);
    }
}
