export class Teacher {
    id?: number;
    name?: string;
    last_name?: string;
    lastname?:string;
    email?: string;
    image?: string;
    full_name?: string;
    info?: {
        position?: string;
        description?: string;
    };
    show?: boolean;
    initials?: string;


    static fromJson(fields: any): Teacher {
        let item = Object.assign(new this(), fields);

        item.last_name = item?.lastname ?? '';
        item.full_name = item.getFullName(fields);
        item.initials = item.computeInitials();

        return item;
    }

    protected getFullName()
    {
        return [this.name || '', this.last_name || '']
            .filter(i => i && i.length)
            .join(' ');
    }


    protected computeInitials() {
        const name: string[] = (this.getFullName() || '').trim().toUpperCase().trim().split(' ');
        let first: string = name[0] && name[0].length > 0 ? (name[0].match(/./ug) || [])[0] || '' : '';
        let second: string = name[1] && name[1].length > 0 ? (name[1].match(/./ug) || [])[0] || '' : '';
        return [first, second].join('').trim();
    }
}
