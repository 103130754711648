import { CompanyStatus } from "./company/company-status-type";
import { Franchise } from "./franchise.model";

export class InputCompany {
    title?: string;
    franchise?: Franchise;
    status?: CompanyStatus;
    image?: string;

    static fromJson(fields: any): InputCompany {
        let item = Object.assign(new this(), fields);

        item.franchise = Franchise.fromJson(fields?.franchise);


        return item;
    }

    static getStatusTitle(status: CompanyStatus = 'active')
    {
        const statuses: {[name in CompanyStatus]?: string} = {
            'active': 'Активна',
            'inactive': 'Неактивна'
        };

        return statuses[status] ?? '';
    }

}
