import { User } from "./user/user.model";
import { FranchiseCourseSummary } from "./franchise-course-summary.model";
import { FranchisePlan } from "./franchise-plan.model";
import { FranchisePreference } from "./franchise-preference.model";

export class Franchise {
    id?: number;
    primary?: boolean;
    admin?: User;
    town?: {
        name?: string;
        id?: number;
    };
    image?: string;
    image_share?: string;
    name?: string;
    email?: string;
    phone?: string;
    facebook?: string;
    address?: string;
    url?: string;
    plan?: FranchisePlan;
    status?: string;
    has_gallery?: boolean;
    active_courses?: any[];
    teachers?: any[];
    students?: any[];
    courses?: FranchiseCourseSummary[];

    administrator?: number;
    logo?: string;
    icon?: string;
    location_url?: string;
    description?: string;
    homepage?: string;
    homepage_description?: string;
    homepage_background?: string;
    company_full_name?: string;
    company_tax_number?: string;
    company_address?: string;
    company_ceo?: string;

    preferences?: FranchisePreference[];

    phoneFormatted?: string;

    static fromJson(fields: any): Franchise {
        let item = Object.assign(new this(), fields);

        item.plan = FranchisePlan.fromJson(fields?.plan);
        item.courses = fields?.courses?.map((item: any) => FranchiseCourseSummary.fromJson(item)) ?? [];
        item.preferences = fields?.preferences?.map((item: any) => FranchisePreference.fromJson(item)) ?? [];

        const phoneNumber = item?.phone?.replace(/[^\d\+]+/g, '') ?? '';
        item.phoneFormatted = !phoneNumber?.startsWith('0')
            ? phoneNumber
            : phoneNumber?.replace(/^0/, '+359');

        return item;
    }

    getPreference(name: string): FranchisePreference|null {
        return this.preferences?.find(item => item?.name?.startsWith(name)) ?? null;
    }

    isPreference(name: string, value: boolean|string|number): boolean {
        return this.getPreference(name)?.value === value;
    }

    getFranchiseStyles(): string|null {
        const preference = this.getPreference(FranchisePreference.PREFERENCE_THEME_COLORS);

        if (!preference) {
            return null;
        }

        const properties = ((preference?.value ?? '') as string)?.split(',')?.map((item) => {
            const [name, value] = item.split('=');

            return [
                '--' + name?.replace(/([A-Z])/g, '-$1').toLowerCase(),
                value,
            ].join(": ");
        });

        return ':root { ' + properties.join(";\n") + " }";
    }
}
