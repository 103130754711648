<p-dialog
    [visible]="true"
    (visibleChange)="onCloseAction($event)"
    [closable]="true"
    [draggable]="false"
    [modal]="true"
    [style]="{'min-width': '40vw', 'width': '991px', 'max-width': '90vw'}"
    styleClass="mx-3 mx-sm-0"
    [header]="headline ?? ('Избор' | transloco)"
>

    <div class="my-3 px-1">
        <div class="input-group search-bar">
            <input type="text"
                    class="form-control"
                    [placeholder]="'Търсене' | transloco"
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                    [(ngModel)]="search"
                    (keyup.enter)="onSearchEnter($event)"
                    (input)="onSearchDebounce($event)"
            />

            <button class="btn btn-outline-secondary submit" type="button"><i class="fa-solid fa-fw fa-magnifying-glass"></i></button>
        </div>
    </div>

    <div class="content"
         infiniteScroll
         [scrollWindow]="false"
         (scrolled)="onScrollDown($event)"
    >
        <div class="px-3" *ngIf="loading && !items?.length">
            <loading [loading]="loading && !items?.length"></loading>
        </div>

        <pre>{{ items | json }}</pre>

        <form *ngIf="form" [formGroup]="form" (submit)="onSubmit($event)"
              class="standard-form d-flex flex-column"
        >
            <div class="col-12 px-3">
                <input type="hidden"
                        id="modules"
                        class="form-control"
                        name="modules"
                        formControlName="items"
                />
                <div  *ngIf="!loading || items?.length"
                    class="items-list px-3 overflow-auto"

                >
                    <a *ngFor="let item of items"
                        href
                        (click)="onSelectedItem($event, item)"
                        class="list-group-item list-group-item-action my-2 p-2 d-flex align-items-center overflow-hidden"
                        [class.selected]="item?.selected"
                        [class.disabled]="item?.disabled"
                    >
                        <i class="fa-solid fa-circle-check fa-fw mx-2" *ngIf="item?.selected"></i>
                        <i class="fa-regular fa-circle fa-fw mx-2" *ngIf="!item?.selected"></i>

                        <div class="row w-100">
                            <div class="col-12 col-sm fw-bold my-1">
                                {{ item?.label ?? '' }}
                            </div>

                            <div class="col-12 col-sm-auto my-1" *ngIf="item?.description?.length">
                                <small>{{ item?.description ?? '' }}</small>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </form>
    </div>

    <ng-template pTemplate="footer">
        <button type="button"
                class="btn btn-primary"
                [disabled]="loading || !selectedItems?.length"
                (click)="onSubmit($event)"
        >
            <i *ngIf="loading" class="fa-solid fa-spinner fa-fw fa-pulse"></i>
            <i *ngIf="!loading" class="fa-solid fa-check fa-fw"></i>

            <span class="mx-2">{{ 'Използвай' | transloco }}</span>
        </button>

        <button type="button"
                class="btn btn-secondary mx-2"
                [disabled]="loading"
                type="button"
                (click)="onCloseAction($event)"
        >
            <i class="fa-solid fa-xmark fa-fw"></i>
            {{ 'Отказ' | transloco }}

        </button>
    </ng-template>
</p-dialog>

