import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[restoreTab]'
})
export class RestoreTabDirective implements OnInit, OnDestroy, AfterViewInit {
    protected subscriptions: Subscription[] = [];

    @Input('restoreTab') name: string = 'tab';
    @Input('restoreTabType') type: ('index'|'label') = 'label';

    @Output('selectedIndex') selectedIndex: EventEmitter<number> = new EventEmitter();

    @HostListener('selectedTabChange', ['$event'])
    onSelectedTabChange(event?: any): void {
        const tabName: string = this.name?.length
            ? this.name
            : 'tab';

        const tabValue: string|number = this.type === 'label'
            ? event?.tab?.textLabel
            : event?.index;

        this.router.navigate([], {queryParams: {
            ...this.route?.snapshot?.queryParams,
            [tabName]: tabValue,
        }});
    }

    constructor(
        private host: MatTabGroup,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit() { }

    ngAfterViewInit() {
        const subscription = this.route.queryParams.subscribe((data: any) => {
            const tabName: string = this.name?.length
                ? this.name
                : 'tab';

            if (tabName in data) {
                const tabIndex = this.type === 'label'
                    ? this.host?._allTabs?.toArray().findIndex((tab) => tab.textLabel === data[tabName])
                    : (Number(data[tabName]) || 0);

                setTimeout(() => {
                    this.selectedIndex.emit(tabIndex);
                    if (typeof this.host?.selectedIndex !== 'undefined') {
                        this.host.selectedIndex = tabIndex;
                    }
                }, 10);
            }
        });
        this.subscriptions.push(subscription);
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(item => item && item.unsubscribe());
    }
}
