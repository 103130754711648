import { Course } from "../course.model";

export class GroupCourse {
    id?: number;
    title?: string;
    course?: Course;

    static fromJson(fields: any): GroupCourse {
        let item = Object.assign(new this(), fields);
        item.course = Course.fromJson(fields?.course);
        return item;
    }

}
