import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Paginate } from '../models/paginate.model';
import { Career } from '../models/career.model'
import { CareerApplication } from '../models/career-application.model';
import { FilterSort } from '../models/filter-sort.model';
import { InputCareer } from '../models/input-career.model';
import { CareerWork } from '../models/career/career-work.model';
import { CareerEmployment } from '../models/career/career-employment.model';
import { Company } from '../models/company.model';
import { Franchise } from '../models/franchise.model';
import { CompanyStatus } from '../models/company/company-status-type';
import { InputCompany } from '../models/input-company.model';

export type CareerServiceIncludeType = (
    'company'
    | 'franchise'
    | 'franchise.town'
    | 'town'
    | 'employments'
    | 'works'
);

export type CompanyServiceIncludeType = (
    'franchise'
    | 'franchise.town'
);

export interface CareerServiceListFilter  {
    limit?: number,
    page?: number,
    q?: string,
    sort?: CareerServiceSortType|FilterSort<CareerServiceSortType>[],
    include?: CareerServiceIncludeType|CareerServiceIncludeType[]
};

export interface CompanyServiceListFilter  {
    limit?: number,
    page?: number,
    q?: string,
    sort?: CompanyServiceSortType|FilterSort<CompanyServiceSortType>[],
    include?: CompanyServiceIncludeType|CompanyServiceIncludeType[]
};


export type CareerServiceSortType = (
    | 'id'
    | 'title'
    | 'date'
    | 'url'
    | 'external_url'
    | 'status'
    | 'updated'
    | 'created'
    | 'town'
    | 'work'
    | 'employment'
    | 'franchise'
    | 'company'
);

export type CompanyServiceSortType = (
    | 'id'
    | 'title'
    | 'image'
    | 'status'
    | 'updated'
    | 'created'
    | 'franchise'
);


@Injectable({
  providedIn: 'root'
})
export class CareerService {

    constructor(
        protected api: ApiService,
    ) { }

    getList(filter?: CareerServiceListFilter): Observable<Paginate<Career>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as CareerServiceSortType);
        filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(',') as any);

        return this.api.get('/careers', {params: filter}).pipe(
            map(data => {
                data = Object.assign(new Paginate<Career>(), data);
                data.data = data.data?.map((item: any)=> Career.fromJson(item));
                return data;
            })
        )
    }

    createCareer(input: InputCareer, include?: CareerServiceIncludeType | CareerServiceIncludeType[]): Observable<InputCareer> {
        const filter = include && include?.length && typeof include === 'object' ? include.join(',') : include;

        return this.api.post('/careers', input, filter && { params: { include: filter } }).pipe(
            map(data => {
                data.data = InputCareer.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Get a single post
     * @param id
     * @returns
     */
    getItem(id: number, include?: CareerServiceIncludeType|CareerServiceIncludeType[]): Observable<{data: Career}> {
        const filter = include && include?.length && typeof include === 'object' ? include.join(',') : include;

        return this.api.get('/careers/' + id, filter && {params: {include: filter}}).pipe(
            map(data => {
                data.data = Career.fromJson(data?.data);
                return data;
            })
        );
    }

    updateCareer(id: number, input: InputCareer, include?: CareerServiceIncludeType|CareerServiceIncludeType[]): Observable<InputCareer> {
        const filter = include && include?.length && typeof include === 'object' ? include.join(',') : include;

        return this.api.post('/careers/' + id, input, filter && {params: {include: filter}}).pipe(
            map(data => {
                data.data = InputCareer.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Delete a job
     * @param id
     * @returns
     */
    delete(id: number):  Observable<any> {
        return this.api.delete('/careers/' + id);
    }

    applyCareer(id: number): Observable<CareerApplication> {
        return this.api.post(`/careers/${id}/applications`).pipe(
            map(data => {
                data.data = CareerApplication.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Send LinkedIn for review to admin
     * @returns
     */
    sendHrReview(): Observable<any> {
        return this.api.post('/careers/hr/review');
    }

    /**
     * Get list of employment types
     * @param filter
     * @returns
     */
    getTypesEmployments(filter?: CareerServiceListFilter, maxCacheTime?: number): Observable<Paginate<CareerEmployment>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as CareerServiceSortType);

        return this.api.get('/careers/types/employments', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<CareerEmployment>(), data);
                data.data = data.data?.map((item: any)=> CareerEmployment.fromJson(item));
                return data;
            })
        )
    }


    /**
     * Get list of work types
     * @param filter
     * @returns
     */
    getTypesWorks(filter?: CareerServiceListFilter, maxCacheTime?: number): Observable<Paginate<CareerWork>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as CareerServiceSortType);

        return this.api.get('/careers/types/works', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<CareerWork>(), data);
                data.data = data.data?.map((item: any)=> CareerWork.fromJson(item));
                return data;
            })
        )
    }


    // (--- Companies ---)

    getAllCompanies(filter?: CompanyServiceListFilter): Observable<Paginate<Company>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as CompanyServiceSortType);
        filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(',') as any);

        return this.api.get('/careers/companies', {params: filter}).pipe(
            map(data => {
                data = Object.assign(new Paginate<Company>(), data);
                data.data = data.data?.map((item: any)=> Company.fromJson(item));
                return data;
            })
        )
    }


    createCompany(input: InputCompany, include?: CompanyServiceIncludeType | CompanyServiceIncludeType[]): Observable<InputCompany> {
        const filter = include && include?.length && typeof include === 'object' ? include.join(',') : include;

        return this.api.post('/careers/companies', input, filter && { params: { include: filter } }).pipe(
            map(data => {
                data.data = InputCompany.fromJson(data?.data);
                return data;
            })
        );
    }


    /**
     * Get a single post
     * @param id
     * @returns
     */
    getCompanyById(id: number, include?: CompanyServiceIncludeType|CompanyServiceIncludeType[]): Observable<{data: Company}> {
        const filter = include && include?.length && typeof include === 'object' ? include.join(',') : include;

        return this.api.get('/careers/companies/' + id, filter && {params: {include: filter}}).pipe(
            map(data => {
                data.data = Company.fromJson(data?.data);
                return data;
            })
        );
    }

    updateCompany(id: number, input: InputCompany, include?: CompanyServiceIncludeType|CompanyServiceIncludeType[]): Observable<InputCompany> {
        const filter = include && include?.length && typeof include === 'object' ? include.join(',') : include;

        return this.api.post('/careers/companies/' + id, input, filter && {params: {include: filter}}).pipe(
            map(data => {
                data.data = InputCompany.fromJson(data?.data);
                return data;
            })
        );
    }


    /**
     * Delete a job
     * @param id
     * @returns
     */
    deleteCompany(id: number):  Observable<any> {
        return this.api.delete('/careers/companies/' + id);
    }

    // (--- Companies ---)
}
