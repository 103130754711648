<div class="lection my-2" *ngIf="events?.length">
    <h3 class="mb-4">
        {{ 'Предстоящи занятия:' | transloco }}
        <div class="separator d-flex align-items-center mt-3">
            <span></span>
            <i class="fa-solid fa-ellipsis mx-2"></i>
        </div>
    </h3>

    <ng-container *ngFor="let event of events">
        <a *ngIf="event?.url?.length" class="lection-card my-3 d-block" [routerLink]="event?.url" >
            <ng-container [ngTemplateOutlet]="eventItem"
                          [ngTemplateOutletContext]="{event}"
            ></ng-container>
        </a>
        <div *ngIf="!event?.url?.length" class="lection-card my-3">
            <ng-container [ngTemplateOutlet]="eventItem"
                          [ngTemplateOutletContext]="{event}"
            ></ng-container>
        </div>
    </ng-container>
</div>

<ng-template #eventItem let-event="event">
    <div class="time">
        <p class="m-0">{{ event?.course ?? '' }} - {{ event?.title ?? '' }}</p>
    </div>

    <div class="row mt-3">
        <div class="col">
            <div class="day-of-week">{{ event?.day_of_week ?? '' }}</div>
            <div class="date">{{ event?.date ?? '' }}</div>
        </div>
        <div class="col-auto time d-flex justify-content-end align-items-end me-1">
            {{ event?.hours_range }}
        </div>
    </div>
</ng-template>


