import { ProjectMessage } from "./project-message.model";
import { ProjectShort } from "./project-short.model";

export class ProjectFile {
    id?: number;
    name?: string;
    filename?: string;
    mime?: string;
    size?: number;
    message?: string;
    checked?: number;
    points?: number;
    date?: string;
    project?: ProjectShort;
    messages?: ProjectMessage[];

    static fromJson(fields: any): ProjectFile {
        let item = Object.assign(new this(), fields);
        item.project = ProjectShort.fromJson(fields?.project);
        item.messages = fields?.messages?.map((item: any) => ProjectMessage.fromJson(item));

        return item;
    }
}
