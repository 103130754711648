export type FranchiseCourseSectionItemStatus = ('active'|'inactive');

export class FranchiseCourseSectionItem {
    title?: string;
    subtitle?: string;
    description?: string;
    thumbnail?: string;
    image?: string;
    alt?: string;
    status?: FranchiseCourseSectionItemStatus;
    position?: number;

    static fromJson(fields: any): FranchiseCourseSectionItem {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
