import { CourseShort } from "./course-short.model";

export class CourseChildDesign {
    id?: number;
    design?: string;
    name?: string;
    courses?: CourseShort[];

    static fromJson(fields: any): CourseChildDesign {
        let item = Object.assign(new this(), fields);
        item.courses = fields?.courses?.map((item: CourseShort) => CourseShort.fromJson(item));
        return item;
    }
}
