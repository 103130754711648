import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Check for control's value equal to another control's value
 */
export const requiredWithout = (fieldsList?: AbstractControl[]): ValidatorFn|any => {

    return (control: AbstractControl): {[key: string]: boolean}|null => {
        const fields: any[] = [
            control?.value,
        ];

        fieldsList?.map(field => {
            fields.push(field.value);
        });

        const result = fields.filter(field => !!field);
        const valid = result.length !== 0;

        return valid ? null : {
            required: true
        };
    };
};
