import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ListingTableComponentColumn } from 'src/app/shared/components/listing-table/listing-table.component';
import { TabComponent } from 'src/app/shared/components/tabs';
import { Paginate } from 'src/app/shared/models/paginate.model';
import { GroupCourse } from 'src/app/shared/models/user/user-group-course.model';
import { User } from 'src/app/shared/models/user/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService, UserServiceFilter, UserServicePaginate } from 'src/app/shared/services/user.service';

type Tab = (
    'groups'
  | 'schedule'
);

interface TabList {
    entity: Paginate<any>;
    filter: UserServicePaginate;
    columns?: ListingTableComponentColumn[];
};
@Component({
    selector: 'overview-teacher',
    templateUrl: './overview-teacher.component.html',
    styleUrls: ['./overview-teacher.component.scss']
})
export class OverviewTeacherComponent implements OnInit, OnDestroy {
    protected readonly tabsDefaultFilter: UserServicePaginate = {
        limit: 10,
        page: 2,
    };

    protected innerId: number|null = 0;
    protected innerLoadingQueue: number = 0;
    protected listSubscription?: Subscription;
    protected subscriptions: Subscription[] = [];

    currentTab: Tab = 'groups';
    tabs: {[key in Tab]: TabList} = {
        groups: {
            entity: new Paginate<GroupCourse>,
            filter: Object.assign({}, this.tabsDefaultFilter),
        },
        schedule: {
            entity: new Paginate<any>,
            filter: Object.assign({}, this.tabsDefaultFilter),
        },
    };

    user: User|null = null;
    teacher: User|null = null;
    showRemoveConfirm: boolean = false;
    showTeacherLoginAsConfirm: boolean = false;

    @Output() loadingChange: EventEmitter<boolean> = new EventEmitter();
    @Input() loading: boolean = false;

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
            } else {
                this.loading = true;
            }

            setTimeout(() => this.loadingChange.emit(this.loading));
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    @Input()
    set id(val: number|null) {
        if (this.innerId !== val) {
            this.innerId = val ?? null;

            this.getUser();
        }
    }
    get id(): number|null {
        return this.innerId;
    }

    @Output() teacherChange: EventEmitter<User> = new EventEmitter();


    constructor(
        private router: Router,
        private messageService: MessageService,
        private userService: UserService,
        private authService: AuthService,
        private locationService: Location,
    ) {
        this.user = this.authService.getUser();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(item => item?.unsubscribe());
    }

    onButtonClicked(buttonId: string) {
        this.showTeacherLoginAsConfirm = buttonId === 'login';
        this.showRemoveConfirm = buttonId === 'remove';
    }

    protected getUser() {
        const filter: UserServiceFilter = {
            include: [
                'groupsTeachers',
            ],
        };

        if (this.user?.id == this.id) {
            filter.include = [];
        }

        this.loadingQueue++;
        const subscription = this.userService.getItem(this.id ?? 0, filter).subscribe({
            next: response => {
                this.loadingQueue--;

                this.teacher = response?.data;
                this.teacherChange.emit(this.teacher);

                const payload: {[key in Tab]?: string} = {
                    groups: 'groups',
                };

                for (let key in payload) {
                    const name: keyof User = payload[key as Tab] as keyof User;
                    const data: any[] = this.teacher[name] as any[];

                    this.tabs?.[key as Tab]?.entity?.append(data);
                    if (this.tabs?.[key as Tab]?.entity ?? false) {
                        this.tabs[key as Tab].entity.meta.per_page = 10;
                        this.tabs[key as Tab].entity.meta.total = data?.length === 10 ? 11 : data?.length;
                        this.tabs[key as Tab].entity.meta.current_page = 1;
                        this.tabs[key as Tab].entity.meta.last_page = data?.length === 10 ? 2 : 1;
                    }
                }
            },
            error: error => {
                this.loadingQueue--;

                this.messageService.add({
                    severity: 'error',
                    detail: translate('Преподавателят не съществува!'),
                });
                this.locationService.back();
            }
        });
        this.subscriptions.push(subscription);
    }

    onRemoveConfirmed(status: string) {
        this.showRemoveConfirm = false;

        if (status === 'yes') {
            const subscription = this.userService.delete(this.teacher?.id ?? 0).subscribe({
                next: data => {
                    this.messageService.add({
                        detail: translate('Успешно премахнат преподавател!'),
                        severity: 'success'
                    });

                    this.locationService.back();
                },
                error: error => {
                    this.messageService.add({
                        detail: translate('Възникна грешка при премахването на преподавател!'),
                        severity: 'error'
                    });
                }
            });
            this.subscriptions.push(subscription);
        }
    }

    onTeacherLoginAsConfirmed(action: string): void {
        this.showTeacherLoginAsConfirm = false;

        if (action !== 'yes') {
            return;
        }

        const subscription = this.userService.signAs(this.teacher?.id ?? 0).subscribe({
            next: data => {
                this.authService.loginAs(data.data.token).subscribe({
                    next: data => {
                        this.messageService.add({
                            severity: 'success',
                            detail: translate('Успешно вписване като преподавател!'),
                        });

                        this.teacher = null;
                        this.router.navigateByUrl('/');
                     }, error: error => {
                        this.messageService.add({
                            severity: 'error',
                            detail: translate('Възникна грешка при вписване като преподавател!'),
                        });
                    }
                });
            },
            error: error => {
                this.messageService.add({
                    severity: 'error',
                    detail: translate('Възникна грешка при вписване като преподавател!'),
                });
            }
        });

        this.subscriptions.push(subscription);
    }

    protected getAdditionalData(tab: Tab, appendData: boolean = false): void {
        if (this.loading) {
            return;
        }

        if (appendData && this.tabs[tab]?.entity?.data?.length) {
            if (this.tabs[tab]?.entity?.reachEnd()) {
                return;
            }
            this.tabs[tab].filter.page = this.tabs[tab]?.entity?.nextPage();
        } else if (!this.tabs[tab]?.entity?.data?.length) {
            this.tabs[tab].filter.page = 1;
        }

        const action: {[key in Tab]?: Function} = {
            groups: this.userService.getGroups,
        };

        if (!action[tab]) {
            return;
        }

        this.loadingQueue++;
        const subscription = action[tab]?.call(this.userService, this.id ?? 0, this.tabs[tab].filter).subscribe({
            next: (data: any) => {
                this.loadingQueue--;

                if (appendData && this.tabs[tab].entity) {
                    data.data = [
                        ...this.tabs[tab]?.entity?.data ?? [],
                        ...data?.data ?? [],
                    ];
                }

                this.tabs[tab].entity = data;
            }, error: (error: any) => {
                this.loadingQueue--;
            }
        });

        this.subscriptions.push(subscription);
    }


    trackBy(index: number, item: any) {
        return item?.id ?? 0;
    }

    onScrollPage(event: any): void {
        // need to wait onTabChange event first, otherwise it could not load data
        // from real active tab
        setTimeout(() => {
            this.getAdditionalData(this.currentTab, true);
        }, 100);
    }

    onTabChange(event: {tab?: TabComponent, index: number}): void {
        this.currentTab = event?.tab?.id as Tab;
    }
}
