<p-dialog
    [visible]="true"
    (visibleChange)="onCloseAction($event)"
    [closable]="true"
    [draggable]="false"
    [modal]="true"
    [style]="{'min-width': '35vw', 'width': '767px', 'max-width': '90vw'}"
    [header]="('Импорт от CSV' | transloco)"
>
    <form class="standard-form my-3" *ngIf="form && !showButtonClose" [formGroup]="form">
        <div class="row" *ngIf="user && (user | roleAny:'admin') && !franchise">
            <div class="col-12 mb-3">
                <label for="franchise">{{ 'Франчайз:' | transloco }}</label>
                <p-dropdown requiredField requiredFieldAttribute="franchise"
                            [class.is-invalid]="errors?.franchise?.status === 'INVALID'"
                            [options]="franchiseList"
                            [placeholder]="'< ' + ('Моля, изберете франчайз' | transloco) + ' >'"
                            formControlName="franchise"
                            optionValue="id"
                            optionLabel="name"
                            styleClass="w-100"
                            inputId="franchise"
                            appendTo="body"
                            (onChange)="onFranchiseChange($event)"
                ></p-dropdown>
                <div class="invalid-feedback" *ngIf="errors?.franchise?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                <div class="invalid-feedback" *ngIf="errors?.franchise?.errors?.any">{{ errors?.franchise?.errors?.any }}</div>

            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-3">
                <label for="formFile" class="form-label">{{ 'CSV файл:' | transloco }}</label>
                <input  requiredField requiredFieldAttribute="formFile"
                        class="form-control mt-0"
                        [class.is-invalid]="errors?.file?.status === 'INVALID'"
                        (change)="handleFileInput($event)"
                        type="file"
                        formControlName="file"
                        id="formFile"
                >
                <div class="invalid-feedback" *ngIf="errors?.file?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                <div class="invalid-feedback" *ngIf="errors?.file?.errors?.mimes">{{ 'Невалиден формат' | transloco }}</div>
                <div class="invalid-feedback" *ngIf="errors?.file?.errors?.any">{{ errors?.file?.errors?.any }}</div>
            </div>
        </div>
    </form>

    <table class="table mt-4" *ngIf="showButtonClose">
        <thead>
            <tr>
                <th colspan="2" class="text-center my-3">{{ 'Резултат от импортирането' | transloco }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td scope="row">{{ ('Новосъздадени профили:' | transloco) }}</td>
                <td>{{ summary?.new ?? 0 }}</td>
            </tr>
            <tr>
                <td scope="row">{{ ('Съществуващи профили:' | transloco) }}</td>
                <td>{{ summary?.old ?? 0 }}</td>
            </tr>
            <tr>
                <td scope="row">{{ ('Редове с грешки:' | transloco) }}</td>
                <td>{{ summary?.failed ?? 0 }}</td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <th scope="row">{{ ('Общо:' | transloco) }}</th>
                <th>{{ summary?.total ?? 0 }}</th>
            </tr>
        </tfoot>
    </table>

    <ng-template pTemplate="footer">
        <div class="row">
            <div class="col-12">
                <button *ngIf="!showButtonClose"
                        type="button"
                        class="btn btn-primary"
                        [disabled]="loading"
                        (click)="onSubmit($event)"
                >
                    <i *ngIf="loading" class="fa-solid fa-spinner fa-fw fa-pulse"></i>
                    <i *ngIf="!loading" class="fa-solid fa-check fa-fw"></i>

                    {{ 'Запиши' | transloco }}
                </button>

                <button *ngIf="!showButtonClose"
                        type="button"
                        class="btn btn-secondary mx-2"
                        [disabled]="loading"
                        type="button"
                        (click)="onCloseAction($event)"
                >

                        <i class="fa-solid fa-xmark fa-fw"></i>
                        {{ 'Отказ' | transloco }}
                </button>

                <button *ngIf="showButtonClose"
                        type="button"
                        class="btn btn-secondary mx-2"
                        [disabled]="loading"
                        type="button"
                        (click)="onCloseAction($event); showButtonClose = false;"
                >

                        <i class="fa-solid fa-xmark fa-fw"></i>
                        {{ 'Затвори' | transloco }}
                </button>
            </div>
        </div>
    </ng-template>

</p-dialog>
