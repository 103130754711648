export class PointOther {
    current?: number = 0;
    title?: string;
    max?: number;

    static fromJson(fields: any): PointOther {
        let item = Object.assign(new this(), fields);

        return item;
    }
}
