import { environment as global } from './environment.global';

export const environment = {
    ...global,
    name: 'local',

    // put here configurations for 'local', but *DON'T* commit changes!


};
