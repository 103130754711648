import { Group } from "./group.model";

export class ProjectShort {
    id?: number;
    status?: string;
    title?: string;
    description?: string;
    group?: Group;

    static fromJson(fields: any): ProjectShort {
        let item = Object.assign(new this(), fields);
        item.group = Group.fromJson(fields?.group);

        return item;
    }
}
