import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map, Observable } from 'rxjs';
import { ModuleTest } from '../models/module-test.model';
import { Paginate } from '../models/paginate.model';
import { Test } from '../models/test.model';
import { TestQuestion } from '../models/test-question.model';
import { TestType } from '../models/test-type.model';
import { TestResult } from '../models/test-result.model';
import { TestUserResult } from '../models/test-user-result.model';
import { FilterSort } from '../models/filter-sort.model';

export type TestServiceIndexIncludeType = (
    'type'
    |'group'
    |'questions.answers'
);

export type TestServiceTestFilter = {
    limit?: number;
    page?: number;
    sort?: string;
    group?: number;
    include?: string|TestServiceIndexIncludeType[];
}

export interface TestInput {
    type: string,
    module: string,
    name: string,
    status?: string,
    profession: string,
    specialty: string,
    course: string,
    pass_percentage: number,
    duration: number,
    has_practice_test?: boolean,

    questions: TestQuestion[],
};

export interface TestResultInput {
    summary?: string,
    exam?: number,
    result?: number,
};

export type TestServiceIndexSortType = (
    'id'
  | 'exam'
  | 'timeout'
  | 'result'
  | 'updated'
  | 'created'
  | 'finish'
);

export interface TestServiceIndexResult  {
    limit?: number,
    page?: number,
    sort?: TestServiceIndexSortType|FilterSort<TestServiceIndexSortType>[];
    q?: string;
    franchise?: number;
    course?: number;
    group?: number;
    user?: number;
    type?: number;
};

@Injectable({
    providedIn: 'root'
})
export class TestService {

    constructor(
        protected api: ApiService,
    ) { }

    getTest(id: number, moduleId: number): Observable<{data: ModuleTest}> {
        return this.api.get('/groups/' + id + '/modules/' + moduleId + '/tests').pipe(
            map(data => {
                data.data = ModuleTest.fromJson(data?.data);
                return data;
            })
        );
    }

    getList(filter?: TestServiceTestFilter, maxCacheTime?: number): Observable<Paginate<Test>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);

        return this.api.get('/tests', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<Test>(), data);
                data.data = data.data?.map((item: any)=> Test.fromJson(item));
                return data;
            })
        );
    }

    getItem(id: number): Observable<{data: Test}> {
        return this.api.get('/tests/' + id).pipe(
            map(data => {
                data.data = Test.fromJson(data?.data);
                return data;
            })
        );
    }

    addTest(data: TestInput): Observable<{data: Test}> {
        return this.api.post('/tests', data).pipe(
            map(data => {
                data.data = Test.fromJson(data?.data);
                return data;
            })
        );
    }

    editTest(id: number, data: TestInput): Observable<{data: Test}> {
        return this.api.post('/tests/' + id, data).pipe(
            map(data => {
                data.data = Test.fromJson(data?.data);
                return data;
            })
        );
    }

    delete(id: number):  Observable<any> {
        return this.api.delete('/tests/' + id);
    }

    getResultList(id: number, filter?: any): Observable<Paginate<TestResult>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);

        return this.api.get('/tests/' + id + '/results', {params: filter}).pipe(
            map(data => {
                data = Object.assign(new Paginate<TestResult>(), data);
                data.data = data.data?.map((item: any)=> TestResult.fromJson(item));
                return data;
            })
        );
    }

    getResultsList(filter?: TestServiceIndexResult): Observable<Paginate<TestUserResult>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as TestServiceIndexSortType);

        return this.api.get('/tests/results', {params: filter}).pipe(
            map(data => {
                data = Object.assign(new Paginate<TestUserResult>(), data);
                data.data = data.data?.map((item: any)=> TestUserResult.fromJson(item));
                return data;
            })
        )
    }

    getResult(id: number): Observable<{data: TestUserResult}> {
        return this.api.get('/tests/results/' + id).pipe(
            map(data => {
                data.data = TestUserResult.fromJson(data?.data);
                return data;
            })
        );
    }

    deleteResult(resultId: number):  Observable<any> {
        return this.api.delete('/tests/result/' + resultId);
    }

    updateResult(resultId: number, data: TestResultInput): Observable<{data: TestUserResult}> {
        return this.api.post('/tests/result/' + resultId, data).pipe(
            map(data => {
                data.data = TestUserResult.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Get test types
     *
     * @param maxCacheTime
     * @returns
     */
    getTestTypes(maxCacheTime?: number): Observable<{data: TestType[]}> {
        return this.api.get('/tests/types', {}, maxCacheTime).pipe(
            map(data => {
                data.data = data?.data?.map((item?: any) => Test.fromJson(item));
                return data;
            })
        );
    }

    finishTest(id: number, data: any): Observable<{data: any}> {
        return this.api.post('/tests/' + id + '/finish', data);
    }

    startTest(id: number, data: any): Observable<{data: any}> {
        return this.api.post('/tests/' + id + '/start', data);
    }

    startPracticeTest(id: number): Observable<{data: any}> {
        return this.api.post('/tests/' + id + '/practice/start');
    }

    finishPracticeTest(id: number, data: any): Observable<{data: any}> {
        return this.api.post('/tests/' + id + '/practice/finish', data);
    }

    markPracticeTest(id:number, data: any): Observable<{data: any}> {
        return this.api.post('/tests/practice/' + id, data);
    }
}
