
export class FranchiseQuery {
    number?: string;
    company?: string;
    address?: string;
    ceo?: string;


    static fromJson(fields: any): FranchiseQuery {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
