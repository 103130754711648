export type UserRoleName = (
    'guest'
    |'super_admin' // deprecated
    |'admin'
    |'partner'
    |'teacher'
    |'student'
    |'marketing'
);

export class UserRole {
    id?: number;
    name?: UserRoleName;
    title?: string;

    static fromJson(fields: any): UserRole {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
