
import { CareerStatus } from "./career/career-status-type";
import { CareerEmployment } from "./career/career-employment.model";
import { CareerWork } from "./career/career-work.model";
import { Company } from "./company.model";
import { Franchise } from "./franchise.model";
import { Town } from "./town.model";

export class Career {
    id?: number;
    title?: string;
    info?: string;
    date?: string;
    town?: Town;
    works?: CareerWork[];
    employments?: CareerEmployment[];
    url?: string;
    external_url?: string;
    status?: CareerStatus;
    created?: string;
    updated?: string;
    company?: Company;
    applied?: boolean;
    franchise?: Franchise;

    static fromJson(fields: any): Career {
        let item = Object.assign(new this(), fields);
        item.company = Company.fromJson(fields?.company);

        item.town = Town.fromJson(fields?.town);

        item.works = fields.works?.map((work: CareerWork) => CareerWork.fromJson(work));

        item.employments = fields.employments?.map((employment: CareerEmployment) => CareerEmployment.fromJson(employment));

        item.franchise = Franchise.fromJson(fields?.franchise);

        return item;
    }

    static getStatusTitle(status: CareerStatus = 'active')
    {
        const statuses: {[name in CareerStatus]?: string} = {
            'active': 'Активна',
            'inactive': 'Неактивна'
        };

        return statuses[status] ?? '';
    }

}
