<div id="carouselGeNeric" class="carousel slide carousel-fade" data-bs-ride="carousel">
    <div class="carousel-inner">
        <div class="carousel-item py-2" *ngFor="let items of slides; let slideIndex=index"
            [class.active]="slideIndex === 0">
            <div class="items justify-content-center align-items-stretch" [ngClass]="{'d-flex': isMultiple}">
                <div [ngClass]="{'items__multiple': isMultiple}" *ngFor="let slide of items">
                  <a [href]="certificate.url" target="_blank">
                    <img [src]="slide" alt="" width="100%">
                  </a>
                </div>
            </div>
            <button *ngIf="slideIndex > 0" class="carousel-control-prev" type="button"
                data-bs-target="#carouselGeNeric" data-bs-slide="prev">
                <i class="fa-solid fa-circle-arrow-left"></i>
            </button>
            <button *ngIf="slideIndex !== slides.length-1" class="carousel-control-next" type="button"
                data-bs-target="#carouselGeNeric" data-bs-slide="next">
                <i class="fa-solid fa-circle-arrow-right"></i>
            </button>
        </div>
    </div>
</div>
