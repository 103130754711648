export class CareerEmployment {
    id?: number;
    title?: string;

    static fromJson(fields: any): CareerEmployment {
        let item = Object.assign(new this(), fields);
        return item;
    }
};


