import { User } from "./user/user.model";

export class GroupStudent {
    id?: number;
    type?: string;
    approved?: boolean;
    approved_date?: string;
    approved_name?: string;
    without_certificate?: boolean;
    confirmed?: boolean;
    student?: User;

    static fromJson(fields: any): GroupStudent {
        let item = Object.assign(new this(), fields);

        item.student = User.fromJson(fields?.student);
        return item;
    }
}
