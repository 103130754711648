import { CompanyStatus } from "./company/company-status-type";
import { Franchise } from "./franchise.model";

export class Company {
    id?: number;
    title?: string;
    image?: string;
    status?: CompanyStatus;
    created?: string;
    updated?: string;
    franchise?: Franchise;


    static fromJson(fields: any): Company {
        let item = Object.assign(new this(), fields);

        item.franchise = Franchise.fromJson(fields?.franchise);

        return item;
    }

    static getStatusTitle(status: CompanyStatus = 'active')
    {
        const statuses: {[name in CompanyStatus]?: string} = {
            'active': 'Активна',
            'inactive': 'Неактивна'
        };

        return statuses[status] ?? '';
    }
}
