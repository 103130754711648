import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { ApiService } from './api.service';
import { CookieService } from './cookie.service';
import { ProfileService } from './profile.service';
import { UserAuth } from 'src/app/shared/models/user/user-auth.model';
import { User } from 'src/app/shared/models/user/user.model';
import { InputLogin } from '../models/input/input-login.model';
import { InputPasswordForgot } from '../models/input/input-password-forgot.model';
import { InputPasswordReset } from '../models/input/input-password-reset.model';
import { I18nService } from './i18n.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    protected readonly USER_TOKEN_NAME: string = 'user';
    protected readonly COOKIE_DAYS_EXPIRE: number = 365;

    protected static isLoaded: boolean = false;
    protected subjectReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    protected subjectStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public user: UserAuth = new UserAuth();

    constructor(
        @Optional() @Inject(PLATFORM_ID) private platform: Object,
        private api: ApiService,
        private router: Router,
        private profileService: ProfileService,
        private cookieService: CookieService,
        private i18nService: I18nService,
    ) {
        if (AuthService.isLoaded && !isPlatformServer(this.platform)) {
            return;
        }
        AuthService.isLoaded = true;

        this.handleTokenChanges();
        this.userLoad();

        if (!this.user.isValid() && this.user.hasToken()) {
            // Init app after refresh
            this.i18nService.init();
            this.getActualProfileData().then((data: any) => {}).catch(e => {});
        } else {
            const isValid = this.user.isValid();

            if (isValid) {
                this.api.updateToken(this.user.getToken());
            }

            this.subjectReady.next(true);
            this.subjectStatus.next(isValid);
        }
    }

    /**
     * Performs an new User Login
     * @param input
     * @returns
     */
    login(input: InputLogin): Observable<UserAuth> {
        return this.api.post('/auth/login', input).pipe(map((data: {data: UserAuth}) => {
            const userAuth: UserAuth = UserAuth.fromJson(data.data);
            this.api.updateToken(userAuth.token);
            this.saveToken(userAuth.token);
            this.userStore(userAuth.user, userAuth.token);

            this.subjectReady.next(true);
            this.subjectStatus.next(this.user.isValid());

            return data.data;
        }));
    }

    /**
     * Sign As another user
     * @param token
     * @returns
     */
    loginAs(token: string): Observable<User> {
        this.user.setToken(token);
        this.api.updateToken(token);
        this.saveToken(token);

        return this.profileService.getProfile().pipe(
            map(profile => {
                this.userStore(profile.data, token);
                this.subjectReady.next(true);
                this.subjectStatus.next(this.user.isValid());

                return profile.data;
            })
        );
    }

    /**
     * Performs a proper logout to the system, which should make token invalidated
     * @param {boolean} skipToken Skip the logout API request
     */
    logout(skipToken: boolean = false): Observable<any> {
        let final: () => any = () => {
            this.api.updateToken('');
            this.user = UserAuth.fromJson({});
            this.removeToken();
            this.subjectReady.next(true);
            this.subjectStatus.next(this.user.isValid());
            localStorage.removeItem(this.USER_TOKEN_NAME);
        };

        if (skipToken) {
            final();
            return of({}).pipe(map((data: any) => {
                return data;
            }));
        }

        return this.api.post('/auth/logout')
            .pipe(map((data: any) => {
                final();
                return data;
            }));
    }

    /**
     * Send an email with reset link
     * @param
     */
    forgot(input: InputPasswordForgot): Observable<UserAuth> {
        return this.api.post('/auth/forgot', input);
    }

    /**
     * Reset password
     * @param
     */
    reset(input: InputPasswordReset): Observable<UserAuth> {
        return this.api.post('/auth/reset', input);
    }

    /**
     * Get authentication status changes over time
     */
    getStatus(): Observable<boolean> {
        return this.subjectStatus.asObservable().pipe(share());
    }

    /**
     * Returns status of the authentication process, i.e. is in progress (false) or finished (true)
     */
    getAuthReady(): Observable<boolean> {
        return this.subjectReady.asObservable().pipe(share());
    }

    /**
     * Update profile data using server
     */
    getActualProfileData(profile?: User): Promise<boolean> {
        return new Promise((resolve, rejected) => {
            this.api.updateToken(this.user.getToken());

            let onError: () => void = (): void => {
                this.logout(true).subscribe({
                    next: (data: any) => {
                        this.subjectReady.next(true);
                        this.subjectStatus.next(this.user.isValid());

                        // In order to force NavigationEnd events firing
                        setTimeout(() => {
                            this.router.navigate(['/']);
                            resolve(this.user.isValid());
                        });
                    },
                    error: error => {
                        rejected(error);
                    }
                });
            };

            if (profile?.id) {
                this.userStore(profile);
                this.subjectReady.next(true);
                this.subjectStatus.next(this.user.isValid());

                resolve(this.user.isValid());
                return;
            }

            this.profileService.getProfile().subscribe({
                next: profile => {
                    this.userStore(profile.data);
                    this.subjectReady.next(true);
                    this.subjectStatus.next(this.user.isValid());

                    resolve(this.user.isValid());
                },
                error: error => {
                    onError();
                    rejected(error);
                }
            });
        });
    }

    getUser(): User|null {
        return this.user?.isValid() ? this.user.user : null;
    }

    /**
     * Get User Token
     */
    protected userLoad(): void {
        const token: string = this.loadToken();
        this.user.setToken(token);
    }

    protected userStore(profile: User, token: string = ''): void {
        const userToken = token || this.user?.getToken();
        this.user = UserAuth.fromJson({user: profile, token: userToken || this.user.token || ''});
        this.api.updateToken(this.user.getToken());
    }

    protected saveToken(token: string): void {
        try {
            this.cookieService.set(this.USER_TOKEN_NAME, token, 365, '/');

            if (isPlatformBrowser(this.platform) && 'localStorage' in window) {
                localStorage.setItem(this.USER_TOKEN_NAME, token);
            }
        } catch (e) { }
    }

    protected loadToken(saveMissing: boolean = true): string {
        try {
            const cookieToken: string = this.cookieService.get(this.USER_TOKEN_NAME);
            const localStorageToken: string = (isPlatformBrowser(this.platform) && 'localStorage' in window)
                ? (localStorage.getItem(this.USER_TOKEN_NAME) ?? '')
                : '';
            const token = cookieToken || localStorageToken || '';

            saveMissing && this.saveToken(token);

            return token;
        } catch (e) {
            return '';
        }
    }

    protected handleTokenChanges(): void {
        try {
            if (!(isPlatformBrowser(this.platform) && 'localStorage' in window && 'addEventListener' in window)) {
                return;
            }

            const self = this;
            window.addEventListener('storage', function(event) {
                if (event.key !== self.USER_TOKEN_NAME
                    || !event.oldValue?.length
                    || event?.oldValue === event?.newValue
                ) {
                    return;
                }

                if (!event?.newValue?.length) {
                    self.logout(true).subscribe(next => {
                        setTimeout(() => {
                            self.router.navigate(['/auth/login']);
                        });
                    });
                    return;
                }

                self.cookieService.delete(self.USER_TOKEN_NAME);
                self.loginAs(event.newValue).subscribe(next => { });
            });
        } catch (e) { }
    }

    protected removeToken(): void {
        try {
            this.cookieService.delete(this.USER_TOKEN_NAME);

            if (isPlatformBrowser(this.platform) && 'localStorage' in window) {
                localStorage.removeItem(this.USER_TOKEN_NAME)
            }
        } catch (e) { }
    }
}
