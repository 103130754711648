<div class="message-wrapper d-flex align-items-end mb-4" [class.flex-row-reverse]="reversed" [class.bubble-end-reverse]="reversed">
    <avatar *ngIf="!reversed || type !== 'messages'"
            class="avatar d-none d-md-flex mb-2"

            [user]="sender || null"
            [size]="avatarSize"
            type="drop"
    ></avatar>

    <div class="message-file-wrapper d-flex flex-column align-items-end">
        <div class="message-cont col-auto w-100 d-flex flex-column"
            [ngClass]="{
                'reversed': reversed
            }"
        >
            <div class="row align-items-start">
                <avatar *ngIf="!reversed && type !== 'messages'"
                        class="col-auto d-flex d-md-none"
                        [class.order-1]="reversed"
                        [user]="sender || null"
                        size="small"
                        type="drop"
                ></avatar>

                <div class="col col-md-12">
                    <h3 class="mb-1" *ngIf="showSender">{{ sender | userName }}</h3>
                    <h4 class="mb-3" *ngIf="showRole">{{ sender?.role?.title }}</h4>

                    <div class="content">{{ message }}</div>

                    <div class="date col-12 mt-2">
                        <span *ngIf="created">{{ created | dateAgo}}</span>
                    </div>
                </div>
            </div>
        </div>

        <a [href]="fileUrl"
            class="my-2 d-inline-block"
            target="_blank"
            *ngIf="fileUrl?.length"

            [ngClass]="{
                'reversed': reversed
            }"
        >
            <i class="fa-fw fa-solid fa-paperclip"></i>
            {{ fileName ?? ('Файл' | transloco) }}
        </a>
    </div>
</div>
