import { HomeworkFile } from "./homework-file.model";
import { Module } from "./module.model";

export class Homework {
    id?: number;
    description?: string;
    file?: HomeworkFile;
    module?: Module;
    files?: HomeworkFile[];

    static fromJson(fields: any): Homework {
        let item = Object.assign(new this(), fields);
        item.files = fields?.files?.map((file: any) => HomeworkFile.fromJson(file));
        item.file = fields?.file ? HomeworkFile.fromJson(fields?.file) : null;
        item.module = fields?.module ? Module.fromJson(fields?.module) : null;
        return item;
    }
}
