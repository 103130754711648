<page-wrap [forceShrinkWidth]="forceShrinkWidth">

    <!-- custom companies -->
    <ng-container *ngIf="!content && !company?.primary && company?.id">
        <!-- use company homepage instead of portal, only if portal home is needed -->
        <a *ngIf="company?.homepage?.length && logoLinkToPortal" class="text-center mb-2 mb-sm-4 d-block px-5" [href]="company?.homepage">
            <ng-container *ngTemplateOutlet="logo"></ng-container>
        </a>

        <!-- either go to portal (from non /auth/login page) or go to /, i.e. redirected again to /auth/login -->
        <a *ngIf="!company?.homepage?.length || !logoLinkToPortal" class="text-center mb-2 mb-sm-4 d-block px-5" [routerLink]="logoLinkToPortal ? '/portal/home' : '/auth/login'"
        >
            <ng-container *ngTemplateOutlet="logo"></ng-container>
        </a>
    </ng-container>

    <!-- main company -->
    <ng-container *ngIf="!content && (company?.primary || !company?.id)">
        <a *ngIf="logoLinkToPortal" class="text-center mb-2 mb-sm-4 d-block px-5" href="https://advanceacademy.bg/">
            <ng-container *ngTemplateOutlet="logo"></ng-container>
        </a>
        <a *ngIf="!logoLinkToPortal" class="text-center mb-2 mb-sm-4 d-block px-5" [routerLink]="'/auth/login'">
            <ng-container *ngTemplateOutlet="logo"></ng-container>
        </a>
    </ng-container>

    <div class="headline" *ngIf="!content">
        <h1 class="text-center">{{ 'Потребителски контролен панел' | transloco }}</h1>
    </div>

    <ng-container *ngTemplateOutlet="content"></ng-container>

    <div class="container" *ngIf="forceShrinkWidth && !loading">
        <div class="row">
            <div class="col-12 col-lg-6 mx-auto">
                <div class="box"><ng-container *ngTemplateOutlet="box"></ng-container></div>
            </div>
        </div>
    </div>
    <div class="box" *ngIf="!forceShrinkWidth && !loading"><ng-container *ngTemplateOutlet="box"></ng-container></div>

    <p class="text-center my-3" *ngIf="!company === null || company?.primary; else companyDetails">
        {{ 'При възникнали въпроси или проблеми:' | transloco }}
        <a [href]="'tel:'+ (company?.phoneFormatted ?? '+359889990030')" class="mw-100 text-nowrap text-truncate d-inline-block align-middle">{{ company?.phone ?? '+359 889990030' }}</a><br />
        <a href="https://advanceacademy.bg" class="text-nowrap">www.advanceacademy.bg</a>
    </p>
    <ng-template #companyDetails>
        <p class="text-center my-3" *ngIf="company?.phone?.length || company?.homepage?.length">
            {{ 'При възникнали въпроси или проблеми:' | transloco }}

            <a *ngIf="company?.phone?.length" [href]="'tel:' + company?.phoneFormatted" class="mw-100 text-nowrap text-truncate d-inline-block align-middle">{{ company?.phone }}</a><br />
            <a *ngIf="company?.homepage?.length" [href]="company?.homepage" class="text-nowrap">{{ company?.homepage }}</a>
        </p>
    </ng-template>
</page-wrap>

<ng-template #box>
    <ng-content></ng-content>
</ng-template>


<ng-template #logo>
    <img src="/assets/img/logos/logo.svg" alt="Advance Academy" class="w-100 mx-auto d-inline-block" *ngIf="!company?.id" />
    <img [src]="company?.logo" [alt]="company?.name" class="w-100 mx-auto d-inline-block custom-logo" *ngIf="company?.id && company?.logo?.length" />
</ng-template>
