export class GroupModuleShort {
    id?: number;
    title?: string;
    number?: number;

    static fromJson(fields: any): GroupModuleShort {
        let item = Object.assign(new this(), fields);

        return item;
    }
}
