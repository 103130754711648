import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {

    transform(value: Array<any>, field: string = 'status', fieldValue: string|number|boolean = 'active'): Array<any> {
        const values: any[] = typeof fieldValue === 'number' || typeof fieldValue === 'boolean'
            ? [fieldValue]
            : (fieldValue + '').split(',');

        return (value || []).filter(item => values.indexOf(item[field]) >= 0);
    }
}
