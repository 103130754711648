export class Town {
    id?: number;
    name?: string;

    static fromJson(fields: any): Town {
        let item = Object.assign(new this(), fields);
        return item;
    }
};


