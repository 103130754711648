import { UntypedFormGroup, UntypedFormControl, Validators, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Component, Output, ViewChild, EventEmitter, ElementRef, Input } from '@angular/core';
import { User } from 'src/app/shared/models/user/user.model';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { translate } from '@ngneat/transloco';
import { Franchise } from '../../models/franchise.model';
import { UserService } from '../../services/user.service';
import { FranchiseService } from '../../services/franchise.service';
import { AuthService } from '../../services/auth.service';
import { ApiValidation } from '../../services/api-validation.service';


@Component({
    selector: 'modal-import-csv',
    templateUrl: './modal-import-csv.component.html',
    styleUrls: ['./modal-import-csv.component.scss']
})
export class ModalImportCSV {
    protected readonly PAGINATION_LIMIT: number = environment.pagination.default;
    protected readonly CACHING_MAX_TIME: number = environment.caching.default;

    @ViewChild('form') formRef: ElementRef|null = null;
    @Output() onAction: EventEmitter<User[]> = new EventEmitter();

    protected subscriptions: Subscription[] = [];
    protected innerLoadingQueue: number = 0;
    protected debounceTimeout: number = 750;
    protected searchDebounce: any = null;

    @Input() franchise: number|null = null;

    loading: boolean = false;
    form: UntypedFormGroup|null = null;
    franchiseList: Franchise[] = [];
    selectedFranchise: Franchise|null = null;
    selectedFile: File|null = null;
    errors: any = null;
    usersFromCSV: User[] = [];
    showButtonClose:boolean = false;
    summary: any = null;
    user?: User = new User;
    users: User[]|null = null;

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
            } else {
                this.loading = true;
            }
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    get paymentControls(): FormArray<FormGroup>|null {
        return this.form?.get('payments') as FormArray;
    }

    constructor(
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private messageService: MessageService,
        private userService: UserService,
        private franchiseService: FranchiseService,
        private apiValidation: ApiValidation,
    ) { }

    ngOnInit(): void {
        this.user = this.authService.getUser() ?? this.user;

        this.initForm();

        if (!this.franchise && this.user?.can(['admin'])) {
            this.getFranchises();
        }
    }

    protected initForm() {
        this.errors = {};
        this.form = this.formBuilder.group({
            franchise:  new UntypedFormControl('', [
                ...(this.user?.can(['admin']) ? [Validators.required] : [])
            ]),
            file:  new UntypedFormControl('', [
                Validators.required,
            ]),
        });
    }

    onCloseAction(e?: any): void {
        this.onAction.emit(this.users ?? []);
    }

    handleFileInput(event: Event): void {
        const element = event.target as HTMLInputElement;

        if (element.files?.length) {
            this.selectedFile = element.files[0];
            this.form?.get('file')?.setValue(this.selectedFile?.name ?? '')
        }
    }

    onFranchiseChange(event: any): void {
        this.selectedFranchise = this.franchiseList.find(item => item.id === event.value) ?? null;
    }

    onSubmit(event?: Event): void {
        if (this.loading) {
            return;
        }

        if (this.franchise) {
            this.form?.get('franchise')?.setValue(this.franchise);
        }

        if (this.form?.invalid || this.selectedFile === null) {
            this.errors = this.form?.controls;
            this.messageService.add({
                severity: 'error',
                summary: translate('Грешка!'),
                detail: translate('Моля, проверете полетата отново!'),
            });
            this.form?.markAllAsTouched();
            return;
        }

        const franchiseId: number = this.selectedFranchise?.id ?? this.franchise ?? 0;

        this.loadingQueue++;
        const uploadSubscription = this.userService.importCsv(this.selectedFile as Blob, this.selectedFile.name, franchiseId).subscribe({
            next: (response) => {
                this.loadingQueue--;
                this.summary = response.data.summary;
                this.users = response.data.users ?? [];
            },
            error: (error) => {
                this.loadingQueue--;
                this.apiValidation.checkBadRequest(error, this.form).then((data: any) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: translate('Грешка!'),
                        detail: data.message
                    });
                    this.errors = data.errors;
                });

            },
            complete: () => {
                this.form?.reset();
                this.selectedFile = null;
                this.selectedFranchise = null;
                this.showButtonClose = true;
            }
        });
        this.subscriptions.push(uploadSubscription);
    }

    protected getFranchises() {
        this.loadingQueue++;
        const subscription = this.franchiseService.getList({limit: 1000}, this.CACHING_MAX_TIME).subscribe({
            next: response => {
                this.loadingQueue--;
                this.franchiseList = response?.data ?? [];
                this.selectedFranchise = this.franchiseList[0];
            },
            error: error => {
                this.loadingQueue--;
            }
        });
        this.subscriptions.push(subscription);
    }
}
