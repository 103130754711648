
<p-dialog
    [visible]="true"
    (visibleChange)="onCloseAction($event)"
    [closable]="true"
    [draggable]="false"
    [modal]="true"
    [style]="{'min-width': '40vw', 'width': '991px', 'max-width': '90vw'}"
    styleClass="mx-3 mx-sm-0"
    [header]="title"
>
    <div class="video-content">
        <form [formGroup]="form" (submit)="onSubmit($event)" *ngIf="form" class="standard-form group-form ng-invalid ng-star-inserted ng-dirty ng-touched">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row gx-5">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <div class="form-group mb-4">
                                    <label for="video">{{ 'Видео ID:' | transloco }}</label>
                                    <input  requiredField requiredFieldAttribute="video"
                                            type="text"
                                            class="form-control"
                                            id="video"
                                            formControlName="video"
                                            [class.is-invalid]="errors?.video?.status === 'INVALID'"
                                    />

                                    <div class="invalid-feedback" *ngIf="errors?.video?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                    <div class="invalid-feedback" *ngIf="errors?.video?.errors?.minlength">{{ 'Полето не може да е по-малко от 2 символа' | transloco }}</div>
                                    <div class="invalid-feedback" *ngIf="errors?.video?.errors?.maxlength">{{ 'Полето не може да е по-голямо от 255 символа' | transloco }}</div>
                                    <div class="invalid-feedback" *ngIf="errors?.video?.errors?.any">{{ errors?.video?.errors?.any }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group mb-4">
                                <label for="status" class="col-12">{{ 'Състояние:' | transloco }}</label>
                                <div class="col-12">
                                    <p-dropdown requiredField requiredFieldAttribute="status"
                                                inputId="status"
                                                [class.is-invalid]="errors?.status?.status === 'INVALID'"
                                                [options]="statusList"
                                                formControlName="status"
                                                optionValue="id"
                                                optionLabel="name"
                                                styleClass="w-100"
                                    ></p-dropdown>
                                    <div class="invalid-feedback" *ngIf="errors?.status?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                    <div class="invalid-feedback" *ngIf="errors?.status?.errors?.any">{{ errors?.name?.errors?.any }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="description">{{ 'Описание:' | transloco }}</label>
                                <textarea   requiredField requiredFieldAttribute="description"
                                            rows="2"
                                            type="text"
                                            id="description"
                                            class="form-control"
                                            formControlName="description"
                                            [class.is-invalid]="errors?.description?.status === 'INVALID'"
                                ></textarea>
                                <div class="invalid-feedback" *ngIf="errors?.description?.errors?.minlength">{{ 'Полето не може да е по-малко от 2 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.description?.errors?.maxlength">{{ 'Полето не може да е по-голямо от 255 символа' | transloco }}</div>
                                <div class="invalid-feedback error-required" *ngIf="errors?.description?.description?.required">{{ 'Полето е задължително' | transloco }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-12 my-2 justify-content-end text-right">
                <div class="d-flex justify-content-end">
                    <button class="btn btn-primary mx-2"
                            [disabled]="loading ? true : null"
                            type="submit"
                            (click)="onSubmit($event)"
                    >
                        <i *ngIf="loading" class="fa-solid fa-spinner fa-fw fa-pulse"></i>
                        <i *ngIf="!loading" class="fa-solid fa-check fa-fw"></i>

                        {{ 'Запиши' | transloco }}
                    </button>
                    <button class="btn btn-secondary mx-2"
                            [disabled]="loading ? true : null"
                            type="submit"
                            (click)="abortSubmit()"
                    >
                        <i *ngIf="loading" class="fa-solid fa-spinner fa-fw fa-pulse"></i>
                        <i *ngIf="!loading" class="fa-solid fa-xmark fa-fw"></i>
                        {{ 'Отказ' | transloco }}
                    </button>
                </div>

            </div>
        </div>
    </div>
</p-dialog>
