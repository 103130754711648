import { GroupModule } from "../group-module.model";
import { PollQuestion } from "./poll-question.model";

export class Poll {
    id?: number;
    type?: ('global'|'module');
    title?: string;
    description?: string;
    popup_title?: string;
    popup_description?: string;
    start_date?: string;
    end_date?: string;
    status?: ('active'|'inactive');
    module?: GroupModule;
    questions?: PollQuestion[];
    static fromJson(fields: any): Poll {
        let item = Object.assign(new this(), fields);
        item.module = GroupModule.fromJson(fields?.module);
        return item;
    }
}
