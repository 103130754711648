<p-dialog
    [visible]="visible"
    (visibleChange)="onCloseAction($event)"
    [closable]="true"
    [draggable]="false"
    [modal]="true"
    [style]="{'min-width': '40vw', 'width': '991px', 'max-width': '90vw'}"
    styleClass="mx-3 mx-sm-0"
    [header]="'Как да работим с новата Online платформа?' | transloco"
>

    <div class="container">
        <div class="row">
            <div class="col-12">
                <div style="padding:49.27% 0 0 0;position:relative;">
                    <iframe src="https://player.vimeo.com/video/882059699?h=675074d924&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="System Final"></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
        </div>
    </div>

</p-dialog>
