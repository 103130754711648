import { Injectable } from '@angular/core';

declare var document: any;

export interface ScriptServiceResult {
    script: string;
    loaded: boolean;
    status: ('loaded'|'preloaded'|'error'),
    additional?: any
};

@Injectable({
    providedIn: 'root'
})
export class ScriptService {

    private scripts: any = {};

    constructor() {
    }

    load(...scripts: string[]): Promise<ScriptServiceResult[]> {
        let promises: any[] = [];
        scripts.forEach((script) => promises.push(this.loadScript(script)));
        return Promise.all(promises);
    }

    loadScript(name: string): Promise<ScriptServiceResult> {
        return new Promise((resolve, reject) => {
            if (name in this.scripts && this.scripts[name].loaded) {
                resolve({script: name, loaded: true, status: 'preloaded'});
            } else if (typeof document === 'undefined' || !document) {
                resolve({script: name, loaded: false, status: 'error', additional: 'Document is not defined'});
            } else {
                this.scripts[name] = {};

                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.onload = () => {
                    this.scripts[name].loaded = true;
                    resolve({script: name, loaded: true, status: 'loaded'});
                };
                script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'error', additional: error});
                script.src = name;

                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    }
}
