
<div class="news mb-4" *ngIf="activeGroups?.data?.length">
    <h3>
        {{ 'Активни курсове' | transloco }}
        <div class="separator secondary d-flex align-items-center mt-3">
            <span></span>
            <i class="fa-solid fa-ellipsis mx-2"></i>
        </div>
    </h3>

    <ng-container *ngFor="let course of activeGroups?.data"
                [ngTemplateOutlet]="item"
                [ngTemplateOutletContext]="{course: course}">
    </ng-container>

    <div class="text-end" *ngIf="(activeGroups?.meta?.total ?? 0) > (activeGroups?.meta?.to ?? 0)">
        <button type="button" class="btn btn-primary px-3" (click)="onLoadMore($event, 'active')">{{ 'Още курсове' | transloco }}</button>
    </div>

</div>

<div class="news mb-4" *ngIf="expiredGroups?.data?.length">
    <h3>
       {{ 'Минали курсове' | transloco }}
        <div class="separator secondary d-flex align-items-center mt-3">
            <span></span>
            <i class="fa-solid fa-ellipsis mx-2"></i>
        </div>
    </h3>

    <ng-container *ngFor="let course of expiredGroups?.data"
                [ngTemplateOutlet]="item"
                [ngTemplateOutletContext]="{course: course}">
    </ng-container>

    <div class="text-end" *ngIf="(expiredGroups?.meta?.total ?? 0) > (expiredGroups?.meta?.to ?? 0)">
        <button type="button" class="btn btn-primary px-3" (click)="onLoadMore($event, 'expired')">{{ 'Още курсове' | transloco }}</button>
    </div>
</div>


<ng-template #item let-course="course">
    <a [routerLink]="baseUrl + course.id" class="course-card-small d-flex align-items-center mb-3">
        <div class="col-2">
            <div class="course-wrapper">
                <div class="course-img">
                    <avatar [photo]="course?.course?.logo" size="small"></avatar>
                </div>
            </div>
        </div>
        <div class="col-10">
            <h2 class="my-2 ms-0 me-2">{{course?.course?.title}}</h2>
        </div>
    </a>
</ng-template>
