import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {
    @Output() remove: EventEmitter<any> = new EventEmitter();

    protected innerType: string = '';

    @Input() customClass: string = '';
    @Input() closeButton: boolean = true;
    @Input() message: string = '';
    @Input() icon: string = '';

    @Input()
    set type(value: string) {
        if (this.innerType !== value) {

            switch (value) {
                case 'info':
                    value = 'info';
                    break;
                case 'error':
                case 'danger':
                    value = 'danger';
                    break;
                case 'secondary':
                case 'not-found':
                    value = 'secondary';
                    break;
                case 'success':
                case 'ok':
                    value = 'success';
                    break;
                case 'warning':
                    value = 'warning';
                    break;
            }

            this.innerType = value;


        }
    }
    get type(): string {
        return this.innerType;
    }

    constructor() { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    onRemove(event: Event): void {
        setTimeout(() => {
            this.remove.emit();
        }, 500);
    }

}
