import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Paginate } from '../models/paginate.model';
import { DOCUMENT } from '@angular/common';
import { ApiService } from './api.service';
import { GlobalMessage } from '../models/global-messages/global-message.model';
import { FilterSort } from '../models/filter-sort.model';

export type GlobalMessageServiceIndexSortType = (
    'franchise'
    | 'status'
    | 'start'
    | 'end'
    | '-franchise'
    | '-status'
    | '-start'
    | '-end'
)

export interface GlobalMessageServiceIndex {
    limit?: number;
    page?: number;
    include?: string;
    sort?: GlobalMessageServiceIndexSortType|FilterSort<GlobalMessageServiceIndexSortType>[];
    expiration?: ('all'|'expired'|'active');
};

export interface InputGlobalMessageService {
    title?: string;
    description?: string;
    start_date?: string;
    end_date?: string;
    status?: ('active'|'inactive');
    franchise_id?: number;
}

@Injectable({
    providedIn: 'root'
})

export class GlobalMessageService {

  constructor(
    protected api: ApiService,
  ) {}


    getList(filter?: GlobalMessageServiceIndex, maxCacheTime?: number): Observable<Paginate<GlobalMessage>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as GlobalMessageServiceIndexSortType);

        return this.api.get('/messages/global', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<GlobalMessage>(), data);
                data.data = data.data?.map((item: any)=> GlobalMessage.fromJson(item));
                return data;
            })
        );
    }

    getItem(id: number): Observable<GlobalMessage> {
        return this.api.get('messages/global' + id).pipe(
            map(data => {
                data.data = GlobalMessage.fromJson(data?.data);
                return data;
            })
        );
    }

    add(data: InputGlobalMessageService): Observable<{data: GlobalMessage}> {
        return this.api.post('messages/global', data).pipe(
            map(data => {
                data.data = GlobalMessage.fromJson(data?.data);
                return data;
            })
        );
    }

    edit(id: number, data: InputGlobalMessageService): Observable<{ data: GlobalMessage }> {
        return this.api.post('messages/global/' + id, data).pipe(
            map(data => {
                data.data = GlobalMessage.fromJson(data?.data);
                return data;
            })
        );
    }

    delete(id: number): Observable<GlobalMessage> {
        return this.api.delete('messages/global/' + id);
    }
}
