import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
    selector: '[itemTemplate]'
})
export class ItemTemplateDirective {
    @Input() type?: string;
    @Input('itemTemplate') name?: string|any;

    constructor(public template: TemplateRef<any>) {}

    getType(): string {
        return this.name;
    }
}
