import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { ProfileService } from './profile.service';
import { AuthService } from './auth.service';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnDestroy {
    private messagesCount = new BehaviorSubject<number>(0);
    private intervalSubscription: Subscription = new Subscription();
    private authStatusSubscription: Subscription = new Subscription();
    private alive = true;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private profileService: ProfileService,
        private authService: AuthService,
    ) {
        this.subscribeToAuthStatus();
    }

    subscribeToAuthStatus() {
        this.authStatusSubscription = this.authService.getStatus().subscribe(isAuthenticated => {
            if (isAuthenticated) {
                this.startNotificationsCheck();
            } else {
                this.stopNotificationsCheck();
                this.setMessagesCount(0);
            }
        });
    }

    startNotificationsCheck() {
        if (isPlatformServer(this.platformId)) {
            this.checkForUnreadMessages();
            return;
        }

        this.intervalSubscription?.unsubscribe();

        this.checkForUnreadMessages();

        this.intervalSubscription = interval(60000)
        .pipe(takeWhile(() => this.alive))
        .subscribe(() => {
            this.checkForUnreadMessages();
        });
    }

    stopNotificationsCheck() {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
    }

    checkForUnreadMessages(): void {
        if (this.authService.getUser()) {
            this.profileService.getNotificationsCount().subscribe({
                next: response => {
                this.setMessagesCount(response?.data?.messages ?? 0);
                },
            });
        }
    }

    setMessagesCount(count: number) {
        this.messagesCount.next(count);
    }

    getMessagesCount() {
        return this.messagesCount.asObservable();
    }

    ngOnDestroy(): void {
        this.stopNotificationsCheck();
        this.authStatusSubscription.unsubscribe();
        this.alive = false;

    }
}
