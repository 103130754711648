<page-wrap [headline]="career?.title ?? ''" [navigation]="navigation">

    <div class=" main-content career">
        <loading [loading]="loading" [hasData]="!!career?.id"></loading>

        <div class="row">
            <div class="col-lg-10 col-12">
                <div class="title mt-2 mb-2 ">
                    <div class="row">
                        <div class="col">
                            <h1 class="title d-none">{{career?.title}}</h1>
                            <div class="description dynamic-content-career-content" [innerHTML]="(career?.info ?? '') | safe:'html'"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-12">

            </div>
        </div>
        <div class="row" *ngIf="!loading || career?.id">
            <div class="col mt-4">
                <a [routerLink]="jobsListUrl" class="btn btn-primary mb-4 mt-2">{{ 'Всички обяви' | transloco }}</a >
            </div>
        </div>
    </div>

    <ng-template section="sidebar">
        <ng-container *ngIf="!loading || career?.id">
            <div>
                <div class="cv mb-4 d-xl-flex d-none text-center">
                    <div class="cv-img mx-auto">
                        <img [src]="career.company?.image" alt="" class="w-100">
                    </div>
                </div>
            </div>
            <div class=" mb-4 d-flex flex-column">
                <ul class="job-info pt-2">
                    <li *ngIf="career.town?.name?.length">
                        <h5>{{ 'Локация:' | transloco }}</h5>
                        <p>{{career.town?.name}}</p>
                    </li>
                    <li *ngIf="!career.town?.name?.length">
                        <h5>{{ 'Локация:' | transloco }}</h5>
                        <p>{{ 'Изцяло дистанционна' }}</p>
                    </li>

                    <li *ngIf="career?.employments?.length">
                        <h5>{{ 'Вид работа:' | transloco }}</h5>
                        <div *ngFor="let employmentType of career.employments">
                            <p>{{employmentType?.title ?? ''}}</p>
                        </div>

                    </li>
                    <li *ngIf="career?.works?.length">
                        <h5>{{ 'Вид заетост:' | transloco }}</h5>
                        <div *ngFor="let workType of career.works">
                            <p>{{workType?.title ?? ''}}</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div>
                <div class="row">
                    <div class="col-12 text-center" *ngIf="(user | roleAny:'student')">
                        <div class="d-flex justify-content-center">
                            <div class="addons">
                                <a routerLink="/profile/cv" class="cv-wrapper" [ngClass]=" user?.cv ? 'success' : 'danger'">
                                    <div>
                                        <p>CV</p>
                                    </div>
                                </a>
                            </div>
                            <div class="addons ps-3">
                                <a routerLink="/careers" fragment="linkedin" class="linkedin-wrapper " [ngClass]="user?.info?.linkedin ? 'success' : 'danger'">
                                    <div>
                                        <p>in</p>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <button class="btn btn-primary mb-4 mt-4 text-uppercase text-nowrap"
                                type="button"
                                *ngIf="!career?.external_url?.length"
                                [disabled]="user?.info?.linkedin === '' || user?.cv === null"
                                (click)="career?.applied ? (showAlert = true) : (showConfirm = true)"
                                [attr.title]="career?.applied ? ('Вече сте кандидатствали за тази позиция.' | transloco) : null"
                                [class.opacity-50]="career?.applied"
                        >
                            <i class="fa-solid fa-triangle-exclamation text-warning me-2" *ngIf="career?.applied"></i>
                            {{career?.applied ? ('Кандидатствал' | transloco) : ('Кандидатствай' | transloco)}}
                        </button>

                        <a  *ngIf="career?.external_url?.length"
                            class="btn btn-primary mb-4 mt-4 text-uppercase text-nowrap"
                            [href]="career?.external_url"
                            target="_blank"
                        >{{ 'Кандидатствай' | transloco }}</a>

                        <div *ngIf="!user?.info?.linkedin || !user?.cv" class="alert alert-warning d-flex align-items-baseline" role="alert">
                            <span class="icon-faq me-2"></span>
                            <small class="pb-0 mb-0">
                                <span *ngIf="!user?.info?.linkedin && !user?.cv">
                                    {{ 'Профилът ти не е завършен, добави своето CV и своя LinkedIn профил, за да кандидатстваш' | transloco}}
                                </span>
                                <span *ngIf="!user?.info?.linkedin && user?.cv">
                                    {{ 'Профилът ти не е завършен, добави своя LinkedIn профил, за да кандидатстваш' | transloco}}
                                </span>
                                <span *ngIf="user?.info?.linkedin && !user?.cv">
                                    {{ 'Профилът ти не е завършен, добави своето CV, за да кандидатстваш' | transloco}}
                                </span>
                            </small>
                        </div>
                    </div>

                    <div class="col-12 text-center" *ngIf="!(user | roleAny:'student') && career?.external_url?.length">
                        <a  class="btn btn-primary mb-4 mt-4 text-uppercase text-nowrap"
                            [href]="career?.external_url"
                            target="_blank"
                        >{{ 'Кандидатствай' | transloco }}</a>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-template>

</page-wrap>

<modal-confirm
    *ngIf="showAlert"
    [body]="'Вече сте кандидатствали за тази позиция.' | transloco"
    (onAction)="showAlert = false"
    [actions]="[{
        name: ('Добре' | transloco), btnClass: 'btn-primary', icon: 'fa-solid fa-check fa-fw'
    }]"
></modal-confirm>

<p-dialog
    [visible]="showConfirm"
    [draggable]="false"
    [modal]="true"
    [style]="{'min-width': '40vw', 'width': '768px', 'max-width': '90vw'}"
    [closeOnEscape]="false"
    [dismissableMask]="false"
    [closable]="false"

    styleClass="mx-3 mx-sm-0 job-confirm"
    [header]="'Желаете ли да кандидатствате за тази позиция?' | transloco"
    *ngIf="!loading"
>
    <div class="test-info">
        <form [formGroup]="applyForm">
            <div class="row">
                <div class="col-12 mb-3">
                    <label class="container-checkbox text-wrap">
                        <input type="checkbox" class="custom-control-input hidden-box" formControlName="gdpr">
                        <span class="checkmark"></span>

                        {{ 'Съгласявам се личните ми данни да се обработват за целите на кандидатстването към тази обява' | transloco }}
                    </label>
                </div>
                <div class="col-12 d-flex justify-content-end align-items-center">
                    <button type="button" class="btn btn-primary btn-block  py-2" (click)="showConfirm=false">
                        {{ 'Отказ' | transloco }}
                    </button>

                    <button
                        type="button"
                        class="btn btn-primary btn-block  py-2 ms-2"
                        [disabled]="!applyForm.valid"
                        (click)="applyJob($event)"
                    >
                        {{ 'Продължи' | transloco }}
                    </button>

                </div>
            </div>
        </form>
    </div>
</p-dialog>
