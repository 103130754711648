import { GroupModuleShort } from "./group-module-short.model";

export class GroupStudentModule {
    id?: number;
    modules?: GroupModuleShort[];

    static fromJson(fields: any): GroupStudentModule {
        let item = Object.assign(new this(), fields);
        item.modules = fields?.videos?.map((item: any) => GroupModuleShort.fromJson(item));

        return item;
    }
}
