import { Component, OnInit, Input } from '@angular/core';
import { Career } from '../../models/career.model';

@Component({
  selector: 'card-job',
  templateUrl: './card-job.component.html',
  styleUrls: ['./card-job.component.scss']
})
export class CardJobComponent implements OnInit {
    @Input() className: string|null = null;
    @Input() career: Career|null = null;
    @Input() imgLogoTop : boolean = false;
    @Input() imgLogoLeft : boolean = false;

    constructor() { }

    ngOnInit(): void {
    }

}
